import * as Sentry from '@sentry/vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import api from '@/api'
import { AmazonSyncControllerApi } from '@/client/amazon'
import type { ProductAcceptRejectDto } from '@/client/products'
import { ProductControllerApi } from '@/client/products'
import { type Pageable, ReportingControllerApi } from '@/client/reporting'
import { i18n } from '@/plugins/i18n'

import type {
  IDateRange,
  IProductDetails,
  IProducts,
  IReportUploadFormData,
  ISummary,
  UploadTypeEnum
} from './types'
const { VUE_APP_BASE_URL } = process.env
const BASE_URL = VUE_APP_BASE_URL.slice(0, VUE_APP_BASE_URL.length - 1)

const productController = new ProductControllerApi(undefined, BASE_URL)
const amazonSyncControllerApi = new AmazonSyncControllerApi(undefined, BASE_URL)
const reportingControllerApi = new ReportingControllerApi(undefined, BASE_URL)

@Module({
  namespaced: true
})
export class MarketplaceAccountsModule extends VuexModule {
  amazonProducts: IProducts | null = null
  reportDateRange: IDateRange | null = null
  reportSummary: ISummary | null = null
  reportType: UploadTypeEnum | null = null
  productDetails: IProductDetails[] | [] = []

  @Mutation
  setAmazonProducts (product: IProducts) {
    this.amazonProducts = product
  }

  @Mutation
  setReportDateRange (res: IDateRange) {
    this.reportDateRange = res
  }

  @Mutation
  setReportType (res: UploadTypeEnum) {
    this.reportType = res
  }

  @Mutation
  setReportSummary (res: ISummary) {
    this.reportSummary = res
  }

  @Mutation
  setProductDetails (res: IProductDetails[]) {
    this.productDetails = res
  }

  @Action({ rawError: true })
  async getAmazonProducts (pagination: Pageable & { sort?: Array<any>, search?:string, changedInAmazon?: boolean }): Promise<void> {
    const userId = this.context.rootGetters['user/id']
    const token = this.context.rootGetters['auth/jwt']
    try {
      const requestOpts = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
      // const res = await api.reporting.getAllAmazonProductsByUserId(token, {
      //   userId,
      //   page: pagination?.pageNumber,
      //   size: pagination?.pageSize,
      //   sort: pagination?.sort,
      //   changedInAmazon: pagination?.changedInAmazon
      //   search: pagination?.search
      // })

      const { data } = await reportingControllerApi.getProductCatalogByUserId(userId, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        page: pagination?.pageNumber,
        size: pagination?.pageSize,
        sort: pagination?.sort
      }, pagination?.search, pagination?.changedInAmazon, requestOpts)
      this.context.commit('setAmazonProducts', data)
    } catch (e) {
      Sentry.captureException(e)
      this.context.dispatch('toasts/addError', i18n.t('pages.errors.422.description'), { root: true })
    }
  }

  @Action({ rawError: true })
  async acceptAmazonProducts (productAcceptRejectDto: ProductAcceptRejectDto): Promise<void> {
    const userId = this.context.rootGetters['user/id']
    const token = this.context.rootGetters['auth/jwt']
    try {
      const requestOpts = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(productAcceptRejectDto)
      }
      await productController.acceptRejectAmazonChanges(
        userId,
        productAcceptRejectDto,
        requestOpts
      )
    } catch (e) {
      Sentry.captureException(e)
      this.context.dispatch('toasts/addError', i18n.t('pages.errors.422.description'), { root: true })
    }
  }

  @Action({ rawError: true })
  async dailyCheck (): Promise<void> {
    const userId = this.context.rootGetters['user/id']
    const token = this.context.rootGetters['auth/jwt']
    try {
      const requestOpts = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
      await amazonSyncControllerApi.checkAmazonProducts(userId, requestOpts)
    } catch (e) {
      Sentry.captureException(e)
      this.context.dispatch('toasts/addError', i18n.t('pages.errors.422.description'), { root: true })
    }
  }

  @Action({ rawError: true })
  async sendEmail (): Promise<void> {
    const userId = this.context.rootGetters['user/id']
    const token = this.context.rootGetters['auth/jwt']
    try {
      const requestOpts = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
      await amazonSyncControllerApi.sendEmailToUser(userId, '', requestOpts)
    } catch (e) {
      Sentry.captureException(e)
      this.context.dispatch('toasts/addError', i18n.t('pages.errors.422.description'), { root: true })
    }
  }

  @Action({ rawError: true })
  async getReportDateRange (): Promise<IDateRange | void> {
    const userId = this.context.rootGetters['user/id']
    const token = this.context.rootGetters['auth/jwt']
    try {
      const res = await api.summary.getReportDateRange(token, userId)
      this.context.commit('setReportDateRange', res)
    } catch (e) {
      Sentry.captureException(e)
      this.context.dispatch('toasts/addError', i18n.t('pages.errors.422.description'), { root: true })
    }
  }

  @Action({ rawError: true })
  async getReportSummary (params: IDateRange): Promise<ISummary | void> {
    const userId = this.context.rootGetters['user/id']
    const token = this.context.rootGetters['auth/jwt']
    try {
      const res = await api.summary.getReportSummary(token, userId, params.first, params.last)
      this.context.commit('setReportSummary', res)
    } catch (e) {
      Sentry.captureException(e)
      this.context.dispatch('toasts/addError', i18n.t('pages.errors.422.description'), { root: true })
    }
  }

  @Action({ rawError: true })
  async getReportType (): Promise<UploadTypeEnum | void> {
    const userId = this.context.rootGetters['user/id']
    const token = this.context.rootGetters['auth/jwt']
    try {
      const res = await api.summary.getReportType(token, userId)
      this.context.commit('setReportType', res)
    } catch (e) {
      Sentry.captureException(e)
      this.context.dispatch('toasts/addError', i18n.t('pages.errors.422.description'), { root: true })
    }
  }

  @Action({ rawError: true })
  async getProductDetails (params: IDateRange): Promise<void> {
    const userId = this.context.rootGetters['user/id']
    const token = this.context.rootGetters['auth/jwt']
    try {
      const res = await api.summary.getProductDetails(
        token,
        userId,
        params.first,
        params.last
      )
      this.context.commit('setProductDetails', res)
    } catch (e) {
      Sentry.captureException(e)
      this.context.dispatch('toasts/addError', i18n.t('pages.errors.422.description'), { root: true })
    }
  }

  @Action({ rawError: true })
  async uploadReport (formData: IReportUploadFormData): Promise<void> {
    try {
      await api.summary.uploadReport(
        this.context.rootGetters['auth/jwt'],
        formData
      )
    } catch (err) {
      Sentry.captureException(err)
    }
  }

  @Action({ rawError: true })
  async transactionSkuUpdate (params: { transactionId: number, sku: string }): Promise<any> {
    const userId = this.context.rootGetters['user/id']
    const token = this.context.rootGetters['auth/jwt']
    const { transactionId, sku } = params
    try {
      return await api.summary.transactionSkuUpdate(token, { userId, transactionId, sku })
      // this.context.commit('setReportSummary', res)
    } catch (e) {
      Sentry.captureException(e)
      this.context.dispatch('toasts/addError', i18n.t('pages.errors.422.description'), { root: true })
      return Promise.reject(e)
    }
  }
}
