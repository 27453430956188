/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface Currency
 */
export interface Currency {
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'currencyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'currencySymbol'?: string;
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    'sortField'?: number;
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    'precision'?: number;
}
/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'currentFileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'originalFileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'checkCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'entityType'?: DocumentEntityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'documentType'?: DocumentDocumentTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'entityId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'id'?: number;
}

export const DocumentEntityTypeEnum = {
    PurchaseOrder: 'PURCHASE_ORDER',
    Invoice: 'INVOICE',
    ShipmentPlan: 'SHIPMENT_PLAN',
    None: 'NONE'
} as const;

export type DocumentEntityTypeEnum = typeof DocumentEntityTypeEnum[keyof typeof DocumentEntityTypeEnum];
export const DocumentDocumentTypeEnum = {
    PurchaseOrder: 'PURCHASE_ORDER',
    Invoice: 'INVOICE',
    ShipmentPlan: 'SHIPMENT_PLAN',
    Other: 'OTHER'
} as const;

export type DocumentDocumentTypeEnum = typeof DocumentDocumentTypeEnum[keyof typeof DocumentDocumentTypeEnum];

/**
 * 
 * @export
 * @interface ErrorDetails
 */
export interface ErrorDetails {
    /**
     * 
     * @type {string}
     * @memberof ErrorDetails
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorDetails
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorDetails
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface PagePurchaseOrder
 */
export interface PagePurchaseOrder {
    /**
     * 
     * @type {number}
     * @memberof PagePurchaseOrder
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePurchaseOrder
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePurchaseOrder
     */
    'size'?: number;
    /**
     * 
     * @type {Array<PurchaseOrder>}
     * @memberof PagePurchaseOrder
     */
    'content'?: Array<PurchaseOrder>;
    /**
     * 
     * @type {number}
     * @memberof PagePurchaseOrder
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PagePurchaseOrder
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {boolean}
     * @memberof PagePurchaseOrder
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePurchaseOrder
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PagePurchaseOrder
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PagePurchaseOrder
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PagePurchaseOrder
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pageable
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageableObject
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
}
/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'transientPaymentsContainerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'unixDueDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'termsDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'amount'?: number;
    /**
     * 
     * @type {Currency}
     * @memberof Payment
     */
    'currency'?: Currency;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'amountInSupplierCurrency'?: number;
    /**
     * 
     * @type {Currency}
     * @memberof Payment
     */
    'supplierCurrency'?: Currency;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'termPercentage'?: number;
}
/**
 * 
 * @export
 * @interface PaymentDto
 */
export interface PaymentDto {
    /**
     * 
     * @type {number}
     * @memberof PaymentDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentDto
     */
    'unixDueDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    'termsDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentDto
     */
    'amount'?: number;
    /**
     * 
     * @type {Currency}
     * @memberof PaymentDto
     */
    'currency'?: Currency;
    /**
     * 
     * @type {number}
     * @memberof PaymentDto
     */
    'amountInSupplierCurrency'?: number;
    /**
     * 
     * @type {Currency}
     * @memberof PaymentDto
     */
    'supplierCurrency'?: Currency;
    /**
     * 
     * @type {number}
     * @memberof PaymentDto
     */
    'termPercentage'?: number;
}
/**
 * 
 * @export
 * @interface PaymentTemplate
 */
export interface PaymentTemplate {
    /**
     * 
     * @type {number}
     * @memberof PaymentTemplate
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentTemplate
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentTemplate
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentTemplate
     */
    'transientPaymentsContainerTemplateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentTemplate
     */
    'termsDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentTemplate
     */
    'amount'?: number;
    /**
     * 
     * @type {Currency}
     * @memberof PaymentTemplate
     */
    'currency'?: Currency;
    /**
     * 
     * @type {number}
     * @memberof PaymentTemplate
     */
    'amountInSupplierCurrency'?: number;
    /**
     * 
     * @type {Currency}
     * @memberof PaymentTemplate
     */
    'supplierCurrency'?: Currency;
    /**
     * 
     * @type {number}
     * @memberof PaymentTemplate
     */
    'termPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentTemplate
     */
    'paymentOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentTemplate
     */
    'numDaysSinceFirstPayment'?: number;
}
/**
 * 
 * @export
 * @interface PaymentsContainer
 */
export interface PaymentsContainer {
    /**
     * 
     * @type {number}
     * @memberof PaymentsContainer
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentsContainer
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentsContainer
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentsContainer
     */
    'transientPurchaseOrderId'?: number;
    /**
     * 
     * @type {Array<Payment>}
     * @memberof PaymentsContainer
     */
    'payments'?: Array<Payment>;
}
/**
 * 
 * @export
 * @interface PaymentsContainerDto
 */
export interface PaymentsContainerDto {
    /**
     * 
     * @type {number}
     * @memberof PaymentsContainerDto
     */
    'transientPurchaseOrderId'?: number;
    /**
     * 
     * @type {PurchaseOrder}
     * @memberof PaymentsContainerDto
     */
    'purchaseOrder'?: PurchaseOrder;
    /**
     * 
     * @type {Array<PaymentDto>}
     * @memberof PaymentsContainerDto
     */
    'payments'?: Array<PaymentDto>;
}
/**
 * 
 * @export
 * @interface PaymentsContainerTemplate
 */
export interface PaymentsContainerTemplate {
    /**
     * 
     * @type {number}
     * @memberof PaymentsContainerTemplate
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentsContainerTemplate
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentsContainerTemplate
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentsContainerTemplate
     */
    'transientSupplierId'?: number;
    /**
     * 
     * @type {Array<PaymentTemplate>}
     * @memberof PaymentsContainerTemplate
     */
    'paymentTemplates'?: Array<PaymentTemplate>;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'asin'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'skuAlias'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'fnSku'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'status'?: ProductStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'isBundle'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'isVariant'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'hasVariants'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'rootProductId'?: number;
    /**
     * 
     * @type {Array<ProductSupplier>}
     * @memberof Product
     */
    'productSuppliers'?: Array<ProductSupplier>;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'longestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'longestSideDimensionUnit'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'medianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'medianSideDimensionUnit'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'shortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'shortSideDimensionUnit'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'dimensionUnit'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'currency'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'changedInAmazon'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'perUnitWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'perUnitWeightUnit'?: ProductPerUnitWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'packagingWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'packagingWeightUnit'?: ProductPackagingWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'numberOfGoodsOnOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'numberOfGoodsInTransit'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'totalValueOfGoodsOnOrderAndInTransit'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'landedUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'factoryUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'inboundUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'weightedAverageUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'confirmationCode'?: number;
}

export const ProductStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type ProductStatusEnum = typeof ProductStatusEnum[keyof typeof ProductStatusEnum];
export const ProductPerUnitWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductPerUnitWeightUnitEnum = typeof ProductPerUnitWeightUnitEnum[keyof typeof ProductPerUnitWeightUnitEnum];
export const ProductPackagingWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductPackagingWeightUnitEnum = typeof ProductPackagingWeightUnitEnum[keyof typeof ProductPackagingWeightUnitEnum];

/**
 * 
 * @export
 * @interface ProductSheetImportDto
 */
export interface ProductSheetImportDto {
    /**
     * 
     * @type {number}
     * @memberof ProductSheetImportDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSheetImportDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSheetImportDto
     */
    'skuAlias'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSheetImportDto
     */
    'productName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductSheetImportDto
     */
    'supplierId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSheetImportDto
     */
    'companyName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductSheetImportDto
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSheetImportDto
     */
    'perUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSheetImportDto
     */
    'factoryUnitCost'?: number;
}
/**
 * 
 * @export
 * @interface ProductSummaryDto
 */
export interface ProductSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ProductSummaryDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSummaryDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSummaryDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ProductSupplier
 */
export interface ProductSupplier {
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'countryOfOrigin'?: ProductSupplierCountryOfOriginEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'productionLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'inTransitLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'totalLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'supplierTermsInDays'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'shippingMethod'?: ProductSupplierShippingMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'material'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'hsCodes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'tariffRates'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'orderQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'unitsPerCase'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'totalCases'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'packagingLongestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'packagingLongestSideDimensionUnit'?: ProductSupplierPackagingLongestSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'packagingMedianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'packagingMedianSideDimensionUnit'?: ProductSupplierPackagingMedianSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'packagingShortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'packagingShortSideDimensionUnit'?: ProductSupplierPackagingShortSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'packagingUnitWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'packagingUnitWeightUnit'?: ProductSupplierPackagingUnitWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'caseLongestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'caseLongestSideDimensionUnit'?: ProductSupplierCaseLongestSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'caseMedianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'caseMedianSideDimensionUnit'?: ProductSupplierCaseMedianSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'caseShortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'caseShortSideDimensionUnit'?: ProductSupplierCaseShortSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'caseGrossWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'caseGrossWeightUnit'?: ProductSupplierCaseGrossWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'cubicMetres'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'costPerCubicMetre'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'totalGrossWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'totalGrossWeightUnit'?: ProductSupplierTotalGrossWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'leadTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'transientSupplierId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'transientProductId'?: number;
}

export const ProductSupplierCountryOfOriginEnum = {
    Us: 'US',
    Ca: 'CA',
    Gb: 'GB',
    Cn: 'CN',
    Mx: 'MX',
    Af: 'AF',
    Ax: 'AX',
    Al: 'AL',
    Dz: 'DZ',
    As: 'AS',
    Ad: 'AD',
    Ao: 'AO',
    Ai: 'AI',
    Aq: 'AQ',
    Ag: 'AG',
    Ar: 'AR',
    Am: 'AM',
    Aw: 'AW',
    Au: 'AU',
    At: 'AT',
    Az: 'AZ',
    Bs: 'BS',
    Bh: 'BH',
    Bd: 'BD',
    Bb: 'BB',
    By: 'BY',
    Be: 'BE',
    Bz: 'BZ',
    Bj: 'BJ',
    Bm: 'BM',
    Bt: 'BT',
    Bo: 'BO',
    Bq: 'BQ',
    Ba: 'BA',
    Bw: 'BW',
    Bv: 'BV',
    Br: 'BR',
    Io: 'IO',
    Bn: 'BN',
    Bg: 'BG',
    Bf: 'BF',
    Bi: 'BI',
    Cv: 'CV',
    Kh: 'KH',
    Cm: 'CM',
    Ky: 'KY',
    Cf: 'CF',
    Td: 'TD',
    Cl: 'CL',
    Cx: 'CX',
    Cc: 'CC',
    Co: 'CO',
    Km: 'KM',
    Cg: 'CG',
    Cd: 'CD',
    Ck: 'CK',
    Cr: 'CR',
    Ci: 'CI',
    Hr: 'HR',
    Cu: 'CU',
    Cw: 'CW',
    Cy: 'CY',
    Cz: 'CZ',
    Dk: 'DK',
    Dj: 'DJ',
    Dm: 'DM',
    Do: 'DO',
    Ec: 'EC',
    Eg: 'EG',
    Sv: 'SV',
    Gq: 'GQ',
    Er: 'ER',
    Ee: 'EE',
    Sz: 'SZ',
    Et: 'ET',
    Fk: 'FK',
    Fo: 'FO',
    Fj: 'FJ',
    Fi: 'FI',
    Fr: 'FR',
    Gf: 'GF',
    Pf: 'PF',
    Tf: 'TF',
    Ga: 'GA',
    Gm: 'GM',
    Ge: 'GE',
    De: 'DE',
    Gh: 'GH',
    Gi: 'GI',
    Gr: 'GR',
    Gl: 'GL',
    Gd: 'GD',
    Gp: 'GP',
    Gu: 'GU',
    Gt: 'GT',
    Gg: 'GG',
    Gn: 'GN',
    Gw: 'GW',
    Gy: 'GY',
    Ht: 'HT',
    Hm: 'HM',
    Va: 'VA',
    Hn: 'HN',
    Hk: 'HK',
    Hu: 'HU',
    Is: 'IS',
    In: 'IN',
    Id: 'ID',
    Ir: 'IR',
    Iq: 'IQ',
    Ie: 'IE',
    Im: 'IM',
    Il: 'IL',
    It: 'IT',
    Jm: 'JM',
    Jp: 'JP',
    Je: 'JE',
    Jo: 'JO',
    Kz: 'KZ',
    Ke: 'KE',
    Ki: 'KI',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Kg: 'KG',
    La: 'LA',
    Lv: 'LV',
    Lb: 'LB',
    Ls: 'LS',
    Lr: 'LR',
    Ly: 'LY',
    Li: 'LI',
    Lt: 'LT',
    Lu: 'LU',
    Mo: 'MO',
    Mg: 'MG',
    Mw: 'MW',
    My: 'MY',
    Mv: 'MV',
    Ml: 'ML',
    Mt: 'MT',
    Mh: 'MH',
    Mq: 'MQ',
    Mr: 'MR',
    Mu: 'MU',
    Yt: 'YT',
    Fm: 'FM',
    Md: 'MD',
    Mc: 'MC',
    Mn: 'MN',
    Me: 'ME',
    Ms: 'MS',
    Ma: 'MA',
    Mz: 'MZ',
    Mm: 'MM',
    Na: 'NA',
    Nr: 'NR',
    Np: 'NP',
    Nl: 'NL',
    Nc: 'NC',
    Nz: 'NZ',
    Ni: 'NI',
    Ne: 'NE',
    Ng: 'NG',
    Nu: 'NU',
    Nf: 'NF',
    Mk: 'MK',
    Mp: 'MP',
    No: 'NO',
    Om: 'OM',
    Pk: 'PK',
    Pw: 'PW',
    Ps: 'PS',
    Pa: 'PA',
    Pg: 'PG',
    Py: 'PY',
    Pe: 'PE',
    Ph: 'PH',
    Pn: 'PN',
    Pl: 'PL',
    Pt: 'PT',
    Pr: 'PR',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Ru: 'RU',
    Rw: 'RW',
    Bl: 'BL',
    Sh: 'SH',
    Kn: 'KN',
    Lc: 'LC',
    Mf: 'MF',
    Pm: 'PM',
    Vc: 'VC',
    Ws: 'WS',
    Sm: 'SM',
    St: 'ST',
    Sa: 'SA',
    Sn: 'SN',
    Rs: 'RS',
    Sc: 'SC',
    Sl: 'SL',
    Sg: 'SG',
    Sx: 'SX',
    Sk: 'SK',
    Si: 'SI',
    Sb: 'SB',
    So: 'SO',
    Za: 'ZA',
    Gs: 'GS',
    Ss: 'SS',
    Es: 'ES',
    Lk: 'LK',
    Sd: 'SD',
    Sr: 'SR',
    Sj: 'SJ',
    Se: 'SE',
    Ch: 'CH',
    Sy: 'SY',
    Tw: 'TW',
    Tj: 'TJ',
    Tz: 'TZ',
    Th: 'TH',
    Tl: 'TL',
    Tg: 'TG',
    Tk: 'TK',
    To: 'TO',
    Tt: 'TT',
    Tn: 'TN',
    Tr: 'TR',
    Tm: 'TM',
    Tc: 'TC',
    Tv: 'TV',
    Ug: 'UG',
    Ua: 'UA',
    Ae: 'AE',
    Um: 'UM',
    Uy: 'UY',
    Uz: 'UZ',
    Vu: 'VU',
    Ve: 'VE',
    Vn: 'VN',
    Vg: 'VG',
    Vi: 'VI',
    Wf: 'WF',
    Eh: 'EH',
    Ye: 'YE',
    Zm: 'ZM',
    Zw: 'ZW'
} as const;

export type ProductSupplierCountryOfOriginEnum = typeof ProductSupplierCountryOfOriginEnum[keyof typeof ProductSupplierCountryOfOriginEnum];
export const ProductSupplierShippingMethodEnum = {
    Air: 'AIR',
    Sea: 'SEA',
    Land: 'LAND'
} as const;

export type ProductSupplierShippingMethodEnum = typeof ProductSupplierShippingMethodEnum[keyof typeof ProductSupplierShippingMethodEnum];
export const ProductSupplierPackagingLongestSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductSupplierPackagingLongestSideDimensionUnitEnum = typeof ProductSupplierPackagingLongestSideDimensionUnitEnum[keyof typeof ProductSupplierPackagingLongestSideDimensionUnitEnum];
export const ProductSupplierPackagingMedianSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductSupplierPackagingMedianSideDimensionUnitEnum = typeof ProductSupplierPackagingMedianSideDimensionUnitEnum[keyof typeof ProductSupplierPackagingMedianSideDimensionUnitEnum];
export const ProductSupplierPackagingShortSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductSupplierPackagingShortSideDimensionUnitEnum = typeof ProductSupplierPackagingShortSideDimensionUnitEnum[keyof typeof ProductSupplierPackagingShortSideDimensionUnitEnum];
export const ProductSupplierPackagingUnitWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductSupplierPackagingUnitWeightUnitEnum = typeof ProductSupplierPackagingUnitWeightUnitEnum[keyof typeof ProductSupplierPackagingUnitWeightUnitEnum];
export const ProductSupplierCaseLongestSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductSupplierCaseLongestSideDimensionUnitEnum = typeof ProductSupplierCaseLongestSideDimensionUnitEnum[keyof typeof ProductSupplierCaseLongestSideDimensionUnitEnum];
export const ProductSupplierCaseMedianSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductSupplierCaseMedianSideDimensionUnitEnum = typeof ProductSupplierCaseMedianSideDimensionUnitEnum[keyof typeof ProductSupplierCaseMedianSideDimensionUnitEnum];
export const ProductSupplierCaseShortSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductSupplierCaseShortSideDimensionUnitEnum = typeof ProductSupplierCaseShortSideDimensionUnitEnum[keyof typeof ProductSupplierCaseShortSideDimensionUnitEnum];
export const ProductSupplierCaseGrossWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductSupplierCaseGrossWeightUnitEnum = typeof ProductSupplierCaseGrossWeightUnitEnum[keyof typeof ProductSupplierCaseGrossWeightUnitEnum];
export const ProductSupplierTotalGrossWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductSupplierTotalGrossWeightUnitEnum = typeof ProductSupplierTotalGrossWeightUnitEnum[keyof typeof ProductSupplierTotalGrossWeightUnitEnum];

/**
 * 
 * @export
 * @interface PurchaseOrder
 */
export interface PurchaseOrder {
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrder
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrder
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrder
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrder
     */
    'generatedDocumentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrder
     */
    'purchaseOrderStatus'?: PurchaseOrderPurchaseOrderStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrder
     */
    'purchaseOrderNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrder
     */
    'notes'?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrder
     */
    'unixOrderDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrder
     */
    'orderLineTotal'?: number;
    /**
     * 
     * @type {Array<PurchaseOrderLine>}
     * @memberof PurchaseOrder
     */
    'purchaseOrderLines'?: Array<PurchaseOrderLine>;
    /**
     * 
     * @type {Supplier}
     * @memberof PurchaseOrder
     */
    'supplier'?: Supplier;
    /**
     * 
     * @type {ShippingInfo}
     * @memberof PurchaseOrder
     */
    'shippingInfo'?: ShippingInfo;
    /**
     * 
     * @type {PaymentsContainer}
     * @memberof PurchaseOrder
     */
    'paymentsContainer'?: PaymentsContainer;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrder
     */
    'internalPurchaseOrderNumber'?: string;
    /**
     * 
     * @type {PurchaseOrderPdfOptions}
     * @memberof PurchaseOrder
     */
    'purchaseOrderPdfOptions'?: PurchaseOrderPdfOptions;
}

export const PurchaseOrderPurchaseOrderStatusEnum = {
    Draft: 'DRAFT',
    Open: 'OPEN',
    Closed: 'CLOSED'
} as const;

export type PurchaseOrderPurchaseOrderStatusEnum = typeof PurchaseOrderPurchaseOrderStatusEnum[keyof typeof PurchaseOrderPurchaseOrderStatusEnum];

/**
 * 
 * @export
 * @interface PurchaseOrderCreateOrUpdateDto
 */
export interface PurchaseOrderCreateOrUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderCreateOrUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderCreateOrUpdateDto
     */
    'purchaseOrderStatus'?: PurchaseOrderCreateOrUpdateDtoPurchaseOrderStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderCreateOrUpdateDto
     */
    'purchaseOrderNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderCreateOrUpdateDto
     */
    'unixOrderDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderCreateOrUpdateDto
     */
    'orderTotal'?: number;
    /**
     * 
     * @type {Array<PurchaseOrderLineCreateOrUpdateDto>}
     * @memberof PurchaseOrderCreateOrUpdateDto
     */
    'purchaseOrderLines'?: Array<PurchaseOrderLineCreateOrUpdateDto>;
    /**
     * 
     * @type {SupplierSummaryDto}
     * @memberof PurchaseOrderCreateOrUpdateDto
     */
    'supplier'?: SupplierSummaryDto;
    /**
     * 
     * @type {ShippingInfoDto}
     * @memberof PurchaseOrderCreateOrUpdateDto
     */
    'shippingInfo'?: ShippingInfoDto;
    /**
     * 
     * @type {PaymentsContainerDto}
     * @memberof PurchaseOrderCreateOrUpdateDto
     */
    'paymentsContainer'?: PaymentsContainerDto;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderCreateOrUpdateDto
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderCreateOrUpdateDto
     */
    'internalPurchaseOrderNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderCreateOrUpdateDto
     */
    'generatedDocumentId'?: number;
}

export const PurchaseOrderCreateOrUpdateDtoPurchaseOrderStatusEnum = {
    Draft: 'DRAFT',
    Open: 'OPEN',
    Closed: 'CLOSED'
} as const;

export type PurchaseOrderCreateOrUpdateDtoPurchaseOrderStatusEnum = typeof PurchaseOrderCreateOrUpdateDtoPurchaseOrderStatusEnum[keyof typeof PurchaseOrderCreateOrUpdateDtoPurchaseOrderStatusEnum];

/**
 * 
 * @export
 * @interface PurchaseOrderLine
 */
export interface PurchaseOrderLine {
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLine
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLine
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLine
     */
    'accountId'?: number;
    /**
     * 
     * @type {Product}
     * @memberof PurchaseOrderLine
     */
    'product'?: Product;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderLine
     */
    'costType'?: PurchaseOrderLineCostTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLine
     */
    'orderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLine
     */
    'unixCreatedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLine
     */
    'unixUpdatedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLine
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLine
     */
    'perUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLine
     */
    'totalCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLine
     */
    'perUnitCostInSupplierCurrency'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLine
     */
    'totalCostInSupplierCurrency'?: number;
}

export const PurchaseOrderLineCostTypeEnum = {
    Shipping: 'SHIPPING',
    FactoryInvoice: 'FACTORY_INVOICE',
    CustomsAndDuties: 'CUSTOMS_AND_DUTIES',
    Inspections: 'INSPECTIONS',
    LocalFreight: 'LOCAL_FREIGHT',
    Photography: 'PHOTOGRAPHY',
    DesignerFees: 'DESIGNER_FEES',
    ProductSamples: 'PRODUCT_SAMPLES',
    ProductTesting: 'PRODUCT_TESTING',
    Storage: 'STORAGE',
    Insurance: 'INSURANCE',
    BankFees: 'BANK_FEES',
    Other: 'OTHER'
} as const;

export type PurchaseOrderLineCostTypeEnum = typeof PurchaseOrderLineCostTypeEnum[keyof typeof PurchaseOrderLineCostTypeEnum];

/**
 * 
 * @export
 * @interface PurchaseOrderLineCreateOrUpdateDto
 */
export interface PurchaseOrderLineCreateOrUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLineCreateOrUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {ProductSummaryDto}
     * @memberof PurchaseOrderLineCreateOrUpdateDto
     */
    'product'?: ProductSummaryDto;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLineCreateOrUpdateDto
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLineCreateOrUpdateDto
     */
    'perUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLineCreateOrUpdateDto
     */
    'totalCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLineCreateOrUpdateDto
     */
    'perUnitCostInSupplierCurrency'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderLineCreateOrUpdateDto
     */
    'totalCostInSupplierCurrency'?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderLineCreateOrUpdateDto
     */
    'costType'?: PurchaseOrderLineCreateOrUpdateDtoCostTypeEnum;
}

export const PurchaseOrderLineCreateOrUpdateDtoCostTypeEnum = {
    Shipping: 'SHIPPING',
    FactoryInvoice: 'FACTORY_INVOICE',
    CustomsAndDuties: 'CUSTOMS_AND_DUTIES',
    Inspections: 'INSPECTIONS',
    LocalFreight: 'LOCAL_FREIGHT',
    Photography: 'PHOTOGRAPHY',
    DesignerFees: 'DESIGNER_FEES',
    ProductSamples: 'PRODUCT_SAMPLES',
    ProductTesting: 'PRODUCT_TESTING',
    Storage: 'STORAGE',
    Insurance: 'INSURANCE',
    BankFees: 'BANK_FEES',
    Other: 'OTHER'
} as const;

export type PurchaseOrderLineCreateOrUpdateDtoCostTypeEnum = typeof PurchaseOrderLineCreateOrUpdateDtoCostTypeEnum[keyof typeof PurchaseOrderLineCreateOrUpdateDtoCostTypeEnum];

/**
 * 
 * @export
 * @interface PurchaseOrderPatchDto
 */
export interface PurchaseOrderPatchDto {
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderPatchDto
     */
    'purchaseOrderStatus'?: PurchaseOrderPatchDtoPurchaseOrderStatusEnum;
}

export const PurchaseOrderPatchDtoPurchaseOrderStatusEnum = {
    Draft: 'DRAFT',
    Open: 'OPEN',
    Closed: 'CLOSED'
} as const;

export type PurchaseOrderPatchDtoPurchaseOrderStatusEnum = typeof PurchaseOrderPatchDtoPurchaseOrderStatusEnum[keyof typeof PurchaseOrderPatchDtoPurchaseOrderStatusEnum];

/**
 * 
 * @export
 * @interface PurchaseOrderPdfOptions
 */
export interface PurchaseOrderPdfOptions {
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderPdfOptions
     */
    'id'?: number;
    /**
     * 
     * @type {Currency}
     * @memberof PurchaseOrderPdfOptions
     */
    'billedCurrency'?: Currency;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderPdfOptions
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderPdfOptions
     */
    'accountId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseOrderPdfOptions
     */
    'showCompanyLogo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseOrderPdfOptions
     */
    'showProductImages'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseOrderPdfOptions
     */
    'showSku'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseOrderPdfOptions
     */
    'showBrand'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseOrderPdfOptions
     */
    'showSkuAlias'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseOrderPdfOptions
     */
    'showPackagingInfo'?: boolean;
}
/**
 * 
 * @export
 * @interface PurchaseOrderPdfOptionsDto
 */
export interface PurchaseOrderPdfOptionsDto {
    /**
     * 
     * @type {Currency}
     * @memberof PurchaseOrderPdfOptionsDto
     */
    'billedCurrency'?: Currency;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseOrderPdfOptionsDto
     */
    'showCompanyLogo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseOrderPdfOptionsDto
     */
    'showProductImages'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseOrderPdfOptionsDto
     */
    'showSku'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseOrderPdfOptionsDto
     */
    'showBrand'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseOrderPdfOptionsDto
     */
    'showSkuAlias'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseOrderPdfOptionsDto
     */
    'showPackagingInfo'?: boolean;
}
/**
 * 
 * @export
 * @interface PurchaseOrderProductImportDto
 */
export interface PurchaseOrderProductImportDto {
    /**
     * 
     * @type {Array<ProductSheetImportDto>}
     * @memberof PurchaseOrderProductImportDto
     */
    'itemsForImport'?: Array<ProductSheetImportDto>;
}
/**
 * 
 * @export
 * @interface PurchaseOrderSummaryDto
 */
export interface PurchaseOrderSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderSummaryDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderSummaryDto
     */
    'purchaseOrderStatus'?: PurchaseOrderSummaryDtoPurchaseOrderStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderSummaryDto
     */
    'purchaseOrderNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderSummaryDto
     */
    'internalPurchaseOrderNumber'?: string;
}

export const PurchaseOrderSummaryDtoPurchaseOrderStatusEnum = {
    Draft: 'DRAFT',
    Open: 'OPEN',
    Closed: 'CLOSED'
} as const;

export type PurchaseOrderSummaryDtoPurchaseOrderStatusEnum = typeof PurchaseOrderSummaryDtoPurchaseOrderStatusEnum[keyof typeof PurchaseOrderSummaryDtoPurchaseOrderStatusEnum];

/**
 * 
 * @export
 * @interface ShippingInfo
 */
export interface ShippingInfo {
    /**
     * 
     * @type {number}
     * @memberof ShippingInfo
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShippingInfo
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShippingInfo
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingInfo
     */
    'uprsNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShippingInfo
     */
    'unixDeliveryDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingInfo
     */
    'portOfLoading'?: string;
    /**
     * 
     * @type {Supplier}
     * @memberof ShippingInfo
     */
    'shipToContact'?: Supplier;
    /**
     * 
     * @type {string}
     * @memberof ShippingInfo
     */
    'shippingMethod'?: ShippingInfoShippingMethodEnum;
}

export const ShippingInfoShippingMethodEnum = {
    Air: 'AIR',
    Sea: 'SEA',
    Land: 'LAND'
} as const;

export type ShippingInfoShippingMethodEnum = typeof ShippingInfoShippingMethodEnum[keyof typeof ShippingInfoShippingMethodEnum];

/**
 * 
 * @export
 * @interface ShippingInfoDto
 */
export interface ShippingInfoDto {
    /**
     * 
     * @type {string}
     * @memberof ShippingInfoDto
     */
    'uprsNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShippingInfoDto
     */
    'unixDeliveryDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingInfoDto
     */
    'portOfLoading'?: string;
    /**
     * 
     * @type {Supplier}
     * @memberof ShippingInfoDto
     */
    'shipToContact'?: Supplier;
    /**
     * 
     * @type {boolean}
     * @memberof ShippingInfoDto
     */
    'saveAsTemplate'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShippingInfoDto
     */
    'shippingMethod'?: ShippingInfoDtoShippingMethodEnum;
}

export const ShippingInfoDtoShippingMethodEnum = {
    Air: 'AIR',
    Sea: 'SEA',
    Land: 'LAND'
} as const;

export type ShippingInfoDtoShippingMethodEnum = typeof ShippingInfoDtoShippingMethodEnum[keyof typeof ShippingInfoDtoShippingMethodEnum];

/**
 * 
 * @export
 * @interface ShippingInfoTemplate
 */
export interface ShippingInfoTemplate {
    /**
     * 
     * @type {number}
     * @memberof ShippingInfoTemplate
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShippingInfoTemplate
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShippingInfoTemplate
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShippingInfoTemplate
     */
    'transientSupplierId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShippingInfoTemplate
     */
    'transientShipToContactId'?: number;
    /**
     * 
     * @type {Supplier}
     * @memberof ShippingInfoTemplate
     */
    'shipToContact'?: Supplier;
    /**
     * 
     * @type {string}
     * @memberof ShippingInfoTemplate
     */
    'shippingMethod'?: ShippingInfoTemplateShippingMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof ShippingInfoTemplate
     */
    'portOfLoading'?: string;
}

export const ShippingInfoTemplateShippingMethodEnum = {
    Air: 'AIR',
    Sea: 'SEA',
    Land: 'LAND'
} as const;

export type ShippingInfoTemplateShippingMethodEnum = typeof ShippingInfoTemplateShippingMethodEnum[keyof typeof ShippingInfoTemplateShippingMethodEnum];

/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {string}
     * @memberof SortObject
     */
    'direction'?: string;
    /**
     * 
     * @type {string}
     * @memberof SortObject
     */
    'nullHandling'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'ascending'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SortObject
     */
    'property'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'ignoreCase'?: boolean;
}
/**
 * 
 * @export
 * @interface Supplier
 */
export interface Supplier {
    /**
     * 
     * @type {number}
     * @memberof Supplier
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'addressLine1'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'addressLine2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'addressLine3'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'addressLine4'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'zipOrPostCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'stateOrProvince'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'telephoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'companyName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Supplier
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Supplier
     */
    'accountId'?: number;
    /**
     * 
     * @type {ShippingInfoTemplate}
     * @memberof Supplier
     */
    'shippingInfoTemplate'?: ShippingInfoTemplate;
    /**
     * 
     * @type {PaymentsContainerTemplate}
     * @memberof Supplier
     */
    'paymentsContainerTemplate'?: PaymentsContainerTemplate;
    /**
     * 
     * @type {Currency}
     * @memberof Supplier
     */
    'preferredCurrency'?: Currency;
}
/**
 * 
 * @export
 * @interface SupplierSummaryDto
 */
export interface SupplierSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof SupplierSummaryDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SupplierSummaryDto
     */
    'companyName'?: string;
}

/**
 * PurchasingAdminControllerApi - axios parameter creator
 * @export
 */
export const PurchasingAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllPurchaseOrders: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/purchasing/sync/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchasingAdminControllerApi - functional programming interface
 * @export
 */
export const PurchasingAdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PurchasingAdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAllPurchaseOrders(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAllPurchaseOrders(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PurchasingAdminControllerApi.syncAllPurchaseOrders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PurchasingAdminControllerApi - factory interface
 * @export
 */
export const PurchasingAdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PurchasingAdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllPurchaseOrders(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.syncAllPurchaseOrders(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PurchasingAdminControllerApi - object-oriented interface
 * @export
 * @class PurchasingAdminControllerApi
 * @extends {BaseAPI}
 */
export class PurchasingAdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasingAdminControllerApi
     */
    public syncAllPurchaseOrders(options?: RawAxiosRequestConfig) {
        return PurchasingAdminControllerApiFp(this.configuration).syncAllPurchaseOrders(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PurchasingControllerApi - axios parameter creator
 * @export
 */
export const PurchasingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkGoogleSheetDataForPurchaseOrderImport: async (userId: number, body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('checkGoogleSheetDataForPurchaseOrderImport', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('checkGoogleSheetDataForPurchaseOrderImport', 'body', body)
            const localVarPath = `/purchasing/{userId}/import-google-sheet/check`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {PurchaseOrderCreateOrUpdateDto} purchaseOrderCreateOrUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPurchaseOrder: async (userId: number, purchaseOrderCreateOrUpdateDto: PurchaseOrderCreateOrUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createPurchaseOrder', 'userId', userId)
            // verify required parameter 'purchaseOrderCreateOrUpdateDto' is not null or undefined
            assertParamExists('createPurchaseOrder', 'purchaseOrderCreateOrUpdateDto', purchaseOrderCreateOrUpdateDto)
            const localVarPath = `/purchasing/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(purchaseOrderCreateOrUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchNextInternalPONumberForUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchNextInternalPONumberForUser', 'userId', userId)
            const localVarPath = `/purchasing/{userId}/internal-po-number`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPurchaseOrderSummariesForUser: async (pageable: Pageable, userId: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('fetchPurchaseOrderSummariesForUser', 'pageable', pageable)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchPurchaseOrderSummariesForUser', 'userId', userId)
            const localVarPath = `/purchasing/{userId}/summaries`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPurchaseOrdersForUser: async (pageable: Pageable, userId: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('fetchPurchaseOrdersForUser', 'pageable', pageable)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchPurchaseOrdersForUser', 'userId', userId)
            const localVarPath = `/purchasing/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPurchaseOrdersForUser1: async (userId: number, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchPurchaseOrdersForUser1', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchPurchaseOrdersForUser1', 'id', id)
            const localVarPath = `/purchasing/{userId}/{id}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {PurchaseOrderPdfOptionsDto} purchaseOrderPdfOptionsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePurchaseOrderPdfForUser: async (userId: number, id: number, purchaseOrderPdfOptionsDto: PurchaseOrderPdfOptionsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('generatePurchaseOrderPdfForUser', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('generatePurchaseOrderPdfForUser', 'id', id)
            // verify required parameter 'purchaseOrderPdfOptionsDto' is not null or undefined
            assertParamExists('generatePurchaseOrderPdfForUser', 'purchaseOrderPdfOptionsDto', purchaseOrderPdfOptionsDto)
            const localVarPath = `/purchasing/{userId}/{id}/generate-pdf`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(purchaseOrderPdfOptionsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {PurchaseOrderProductImportDto} purchaseOrderProductImportDto 
         * @param {boolean} [skipMissing] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPurchaseOrdersFromGoogleSheet: async (userId: number, purchaseOrderProductImportDto: PurchaseOrderProductImportDto, skipMissing?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('importPurchaseOrdersFromGoogleSheet', 'userId', userId)
            // verify required parameter 'purchaseOrderProductImportDto' is not null or undefined
            assertParamExists('importPurchaseOrdersFromGoogleSheet', 'purchaseOrderProductImportDto', purchaseOrderProductImportDto)
            const localVarPath = `/purchasing/{userId}/import-google-sheet`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skipMissing !== undefined) {
                localVarQueryParameter['skip-missing'] = skipMissing;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(purchaseOrderProductImportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {PurchaseOrderCreateOrUpdateDto} purchaseOrderCreateOrUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePurchaseOrder: async (userId: number, id: number, purchaseOrderCreateOrUpdateDto: PurchaseOrderCreateOrUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updatePurchaseOrder', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePurchaseOrder', 'id', id)
            // verify required parameter 'purchaseOrderCreateOrUpdateDto' is not null or undefined
            assertParamExists('updatePurchaseOrder', 'purchaseOrderCreateOrUpdateDto', purchaseOrderCreateOrUpdateDto)
            const localVarPath = `/purchasing/{userId}/{id}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(purchaseOrderCreateOrUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {PurchaseOrderPatchDto} purchaseOrderPatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePurchaseOrderValuesForUser: async (userId: number, id: number, purchaseOrderPatchDto: PurchaseOrderPatchDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updatePurchaseOrderValuesForUser', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePurchaseOrderValuesForUser', 'id', id)
            // verify required parameter 'purchaseOrderPatchDto' is not null or undefined
            assertParamExists('updatePurchaseOrderValuesForUser', 'purchaseOrderPatchDto', purchaseOrderPatchDto)
            const localVarPath = `/purchasing/{userId}/{id}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (purchaseOrderPatchDto !== undefined) {
                for (const [key, value] of Object.entries(purchaseOrderPatchDto)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchasingControllerApi - functional programming interface
 * @export
 */
export const PurchasingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PurchasingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkGoogleSheetDataForPurchaseOrderImport(userId: number, body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseOrderProductImportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkGoogleSheetDataForPurchaseOrderImport(userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PurchasingControllerApi.checkGoogleSheetDataForPurchaseOrderImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {PurchaseOrderCreateOrUpdateDto} purchaseOrderCreateOrUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPurchaseOrder(userId: number, purchaseOrderCreateOrUpdateDto: PurchaseOrderCreateOrUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPurchaseOrder(userId, purchaseOrderCreateOrUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PurchasingControllerApi.createPurchaseOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchNextInternalPONumberForUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchNextInternalPONumberForUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PurchasingControllerApi.fetchNextInternalPONumberForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPurchaseOrderSummariesForUser(pageable: Pageable, userId: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PurchaseOrderSummaryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPurchaseOrderSummariesForUser(pageable, userId, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PurchasingControllerApi.fetchPurchaseOrderSummariesForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPurchaseOrdersForUser(pageable: Pageable, userId: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePurchaseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPurchaseOrdersForUser(pageable, userId, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PurchasingControllerApi.fetchPurchaseOrdersForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPurchaseOrdersForUser1(userId: number, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPurchaseOrdersForUser1(userId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PurchasingControllerApi.fetchPurchaseOrdersForUser1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {PurchaseOrderPdfOptionsDto} purchaseOrderPdfOptionsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generatePurchaseOrderPdfForUser(userId: number, id: number, purchaseOrderPdfOptionsDto: PurchaseOrderPdfOptionsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generatePurchaseOrderPdfForUser(userId, id, purchaseOrderPdfOptionsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PurchasingControllerApi.generatePurchaseOrderPdfForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {PurchaseOrderProductImportDto} purchaseOrderProductImportDto 
         * @param {boolean} [skipMissing] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importPurchaseOrdersFromGoogleSheet(userId: number, purchaseOrderProductImportDto: PurchaseOrderProductImportDto, skipMissing?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PurchaseOrder>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importPurchaseOrdersFromGoogleSheet(userId, purchaseOrderProductImportDto, skipMissing, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PurchasingControllerApi.importPurchaseOrdersFromGoogleSheet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {PurchaseOrderCreateOrUpdateDto} purchaseOrderCreateOrUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePurchaseOrder(userId: number, id: number, purchaseOrderCreateOrUpdateDto: PurchaseOrderCreateOrUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePurchaseOrder(userId, id, purchaseOrderCreateOrUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PurchasingControllerApi.updatePurchaseOrder']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {PurchaseOrderPatchDto} purchaseOrderPatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePurchaseOrderValuesForUser(userId: number, id: number, purchaseOrderPatchDto: PurchaseOrderPatchDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchaseOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePurchaseOrderValuesForUser(userId, id, purchaseOrderPatchDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PurchasingControllerApi.updatePurchaseOrderValuesForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PurchasingControllerApi - factory interface
 * @export
 */
export const PurchasingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PurchasingControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkGoogleSheetDataForPurchaseOrderImport(userId: number, body: string, options?: any): AxiosPromise<PurchaseOrderProductImportDto> {
            return localVarFp.checkGoogleSheetDataForPurchaseOrderImport(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {PurchaseOrderCreateOrUpdateDto} purchaseOrderCreateOrUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPurchaseOrder(userId: number, purchaseOrderCreateOrUpdateDto: PurchaseOrderCreateOrUpdateDto, options?: any): AxiosPromise<PurchaseOrder> {
            return localVarFp.createPurchaseOrder(userId, purchaseOrderCreateOrUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchNextInternalPONumberForUser(userId: number, options?: any): AxiosPromise<string> {
            return localVarFp.fetchNextInternalPONumberForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPurchaseOrderSummariesForUser(pageable: Pageable, userId: number, search?: string, options?: any): AxiosPromise<Array<PurchaseOrderSummaryDto>> {
            return localVarFp.fetchPurchaseOrderSummariesForUser(pageable, userId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPurchaseOrdersForUser(pageable: Pageable, userId: number, search?: string, options?: any): AxiosPromise<PagePurchaseOrder> {
            return localVarFp.fetchPurchaseOrdersForUser(pageable, userId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPurchaseOrdersForUser1(userId: number, id: number, options?: any): AxiosPromise<PurchaseOrder> {
            return localVarFp.fetchPurchaseOrdersForUser1(userId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {PurchaseOrderPdfOptionsDto} purchaseOrderPdfOptionsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePurchaseOrderPdfForUser(userId: number, id: number, purchaseOrderPdfOptionsDto: PurchaseOrderPdfOptionsDto, options?: any): AxiosPromise<Document> {
            return localVarFp.generatePurchaseOrderPdfForUser(userId, id, purchaseOrderPdfOptionsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {PurchaseOrderProductImportDto} purchaseOrderProductImportDto 
         * @param {boolean} [skipMissing] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPurchaseOrdersFromGoogleSheet(userId: number, purchaseOrderProductImportDto: PurchaseOrderProductImportDto, skipMissing?: boolean, options?: any): AxiosPromise<Array<PurchaseOrder>> {
            return localVarFp.importPurchaseOrdersFromGoogleSheet(userId, purchaseOrderProductImportDto, skipMissing, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {PurchaseOrderCreateOrUpdateDto} purchaseOrderCreateOrUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePurchaseOrder(userId: number, id: number, purchaseOrderCreateOrUpdateDto: PurchaseOrderCreateOrUpdateDto, options?: any): AxiosPromise<PurchaseOrder> {
            return localVarFp.updatePurchaseOrder(userId, id, purchaseOrderCreateOrUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {PurchaseOrderPatchDto} purchaseOrderPatchDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePurchaseOrderValuesForUser(userId: number, id: number, purchaseOrderPatchDto: PurchaseOrderPatchDto, options?: any): AxiosPromise<PurchaseOrder> {
            return localVarFp.updatePurchaseOrderValuesForUser(userId, id, purchaseOrderPatchDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PurchasingControllerApi - object-oriented interface
 * @export
 * @class PurchasingControllerApi
 * @extends {BaseAPI}
 */
export class PurchasingControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasingControllerApi
     */
    public checkGoogleSheetDataForPurchaseOrderImport(userId: number, body: string, options?: RawAxiosRequestConfig) {
        return PurchasingControllerApiFp(this.configuration).checkGoogleSheetDataForPurchaseOrderImport(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {PurchaseOrderCreateOrUpdateDto} purchaseOrderCreateOrUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasingControllerApi
     */
    public createPurchaseOrder(userId: number, purchaseOrderCreateOrUpdateDto: PurchaseOrderCreateOrUpdateDto, options?: RawAxiosRequestConfig) {
        return PurchasingControllerApiFp(this.configuration).createPurchaseOrder(userId, purchaseOrderCreateOrUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasingControllerApi
     */
    public fetchNextInternalPONumberForUser(userId: number, options?: RawAxiosRequestConfig) {
        return PurchasingControllerApiFp(this.configuration).fetchNextInternalPONumberForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {number} userId 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasingControllerApi
     */
    public fetchPurchaseOrderSummariesForUser(pageable: Pageable, userId: number, search?: string, options?: RawAxiosRequestConfig) {
        return PurchasingControllerApiFp(this.configuration).fetchPurchaseOrderSummariesForUser(pageable, userId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {number} userId 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasingControllerApi
     */
    public fetchPurchaseOrdersForUser(pageable: Pageable, userId: number, search?: string, options?: RawAxiosRequestConfig) {
        return PurchasingControllerApiFp(this.configuration).fetchPurchaseOrdersForUser(pageable, userId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasingControllerApi
     */
    public fetchPurchaseOrdersForUser1(userId: number, id: number, options?: RawAxiosRequestConfig) {
        return PurchasingControllerApiFp(this.configuration).fetchPurchaseOrdersForUser1(userId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} id 
     * @param {PurchaseOrderPdfOptionsDto} purchaseOrderPdfOptionsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasingControllerApi
     */
    public generatePurchaseOrderPdfForUser(userId: number, id: number, purchaseOrderPdfOptionsDto: PurchaseOrderPdfOptionsDto, options?: RawAxiosRequestConfig) {
        return PurchasingControllerApiFp(this.configuration).generatePurchaseOrderPdfForUser(userId, id, purchaseOrderPdfOptionsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {PurchaseOrderProductImportDto} purchaseOrderProductImportDto 
     * @param {boolean} [skipMissing] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasingControllerApi
     */
    public importPurchaseOrdersFromGoogleSheet(userId: number, purchaseOrderProductImportDto: PurchaseOrderProductImportDto, skipMissing?: boolean, options?: RawAxiosRequestConfig) {
        return PurchasingControllerApiFp(this.configuration).importPurchaseOrdersFromGoogleSheet(userId, purchaseOrderProductImportDto, skipMissing, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} id 
     * @param {PurchaseOrderCreateOrUpdateDto} purchaseOrderCreateOrUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasingControllerApi
     */
    public updatePurchaseOrder(userId: number, id: number, purchaseOrderCreateOrUpdateDto: PurchaseOrderCreateOrUpdateDto, options?: RawAxiosRequestConfig) {
        return PurchasingControllerApiFp(this.configuration).updatePurchaseOrder(userId, id, purchaseOrderCreateOrUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} id 
     * @param {PurchaseOrderPatchDto} purchaseOrderPatchDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasingControllerApi
     */
    public updatePurchaseOrderValuesForUser(userId: number, id: number, purchaseOrderPatchDto: PurchaseOrderPatchDto, options?: RawAxiosRequestConfig) {
        return PurchasingControllerApiFp(this.configuration).updatePurchaseOrderValuesForUser(userId, id, purchaseOrderPatchDto, options).then((request) => request(this.axios, this.basePath));
    }
}



