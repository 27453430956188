/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface Brand
 */
export interface Brand {
    /**
     * 
     * @type {number}
     * @memberof Brand
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Brand
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof Brand
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Brand
     */
    'accountId'?: number;
}
/**
 * 
 * @export
 * @interface BrandDto
 */
export interface BrandDto {
    /**
     * 
     * @type {number}
     * @memberof BrandDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof BrandDto
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BrandDto
     */
    'accountId'?: number;
}
/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {number}
     * @memberof Group
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ImportGoogleSheetDto
 */
export interface ImportGoogleSheetDto {
    /**
     * 
     * @type {string}
     * @memberof ImportGoogleSheetDto
     */
    'googleSheetsUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof ImportGoogleSheetDto
     */
    'supplierId'?: number;
}
/**
 * 
 * @export
 * @interface ImportProductCsvRequest
 */
export interface ImportProductCsvRequest {
    /**
     * 
     * @type {File}
     * @memberof ImportProductCsvRequest
     */
    'file'?: File;
}
/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'notificationStatus'?: NotificationDtoNotificationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'notificationType'?: NotificationDtoNotificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'notificationSource'?: NotificationDtoNotificationSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'entityType'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'messageDateTime'?: string;
}

export const NotificationDtoNotificationStatusEnum = {
    Unread: 'UNREAD',
    Read: 'READ',
    Dismissed: 'DISMISSED'
} as const;

export type NotificationDtoNotificationStatusEnum = typeof NotificationDtoNotificationStatusEnum[keyof typeof NotificationDtoNotificationStatusEnum];
export const NotificationDtoNotificationTypeEnum = {
    Info: 'INFO',
    ActionNeeded: 'ACTION_NEEDED',
    Error: 'ERROR'
} as const;

export type NotificationDtoNotificationTypeEnum = typeof NotificationDtoNotificationTypeEnum[keyof typeof NotificationDtoNotificationTypeEnum];
export const NotificationDtoNotificationSourceEnum = {
    GatewayService: 'GATEWAY_SERVICE',
    InvoiceService: 'INVOICE_SERVICE',
    NotificationService: 'NOTIFICATION_SERVICE',
    ProductService: 'PRODUCT_SERVICE',
    ReportingService: 'REPORTING_SERVICE',
    SchedulerService: 'SCHEDULER_SERVICE',
    SupplierService: 'SUPPLIER_SERVICE',
    ShopifyService: 'SHOPIFY_SERVICE',
    AmazonService: 'AMAZON_SERVICE',
    UserService: 'USER_SERVICE',
    XeroService: 'XERO_SERVICE',
    OrdersService: 'ORDERS_SERVICE',
    Sv1Service: 'SV1_SERVICE',
    PurchasingService: 'PURCHASING_SERVICE'
} as const;

export type NotificationDtoNotificationSourceEnum = typeof NotificationDtoNotificationSourceEnum[keyof typeof NotificationDtoNotificationSourceEnum];

/**
 * 
 * @export
 * @interface PageBrand
 */
export interface PageBrand {
    /**
     * 
     * @type {number}
     * @memberof PageBrand
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageBrand
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageBrand
     */
    'size'?: number;
    /**
     * 
     * @type {Array<Brand>}
     * @memberof PageBrand
     */
    'content'?: Array<Brand>;
    /**
     * 
     * @type {number}
     * @memberof PageBrand
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageBrand
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageBrand
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageBrand
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageBrand
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageBrand
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageBrand
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageProduct
 */
export interface PageProduct {
    /**
     * 
     * @type {number}
     * @memberof PageProduct
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProduct
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProduct
     */
    'size'?: number;
    /**
     * 
     * @type {Array<Product>}
     * @memberof PageProduct
     */
    'content'?: Array<Product>;
    /**
     * 
     * @type {number}
     * @memberof PageProduct
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageProduct
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageProduct
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageProduct
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageProduct
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageProduct
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageProduct
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageProductChangeLog
 */
export interface PageProductChangeLog {
    /**
     * 
     * @type {number}
     * @memberof PageProductChangeLog
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProductChangeLog
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProductChangeLog
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ProductChangeLog>}
     * @memberof PageProductChangeLog
     */
    'content'?: Array<ProductChangeLog>;
    /**
     * 
     * @type {number}
     * @memberof PageProductChangeLog
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageProductChangeLog
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageProductChangeLog
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageProductChangeLog
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageProductChangeLog
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageProductChangeLog
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageProductChangeLog
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'offset'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof Pageable
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    'paged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    'unpaged'?: boolean;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'skuAlias'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'fnSku'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'asin'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'status'?: ProductStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'tag'?: string;
    /**
     * 
     * @type {Array<ProductSupplier>}
     * @memberof Product
     */
    'productSuppliers'?: Array<ProductSupplier>;
    /**
     * 
     * @type {Group}
     * @memberof Product
     */
    'group'?: Group;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'fulfilledBy'?: string;
    /**
     * 
     * @type {Brand}
     * @memberof Product
     */
    'brand'?: Brand;
    /**
     * 
     * @type {Brand}
     * @memberof Product
     */
    'reBrand'?: Brand;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'channel'?: ProductChannelEnum;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'longestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'longestSideDimensionUnit'?: ProductLongestSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'medianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'medianSideDimensionUnit'?: ProductMedianSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'shortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'shortSideDimensionUnit'?: ProductShortSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'unitWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'unitWeightUnit'?: ProductUnitWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'packagingWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'packagingWeightUnit'?: ProductPackagingWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'shippingWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'shippingWeightUnit'?: ProductShippingWeightUnitEnum;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Product
     */
    'products'?: Array<Product>;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'isBundle': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'changedInAmazon'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'amazonFees'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'amazonProductStatus'?: ProductAmazonProductStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'changeDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'listingPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'productSizeTier'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'perUnitWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'perUnitWeightUnit'?: ProductPerUnitWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'landedUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'factoryUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'inboundUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'weightedAverageUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'costMargin'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estReferralFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estReferralFeePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'fbaFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'fbaFeePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estFeeTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estFeeTotalPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estVariableClosingFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estVariableClosingFeePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estOrderHandlingFeePerOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estOrderHandlingFeePerOrderPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estPickPackFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estPickPackFeePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estWeightHandlingFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'estWeightHandlingFeePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'grossProfit'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'grossMarginPercentage'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'isVariant'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'hasVariants'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'rootProductId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'variantNumber'?: number;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Product
     */
    'variants'?: Array<Product>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'session'?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'numberOfGoodsOnOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'numberOfGoodsInTransit'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'totalValueOfGoodsOnOrderAndInTransit'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'unixOpeningBalancesDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'openingInventoryOnHand'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'openingLandedUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'openingFactoryUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'openingInboundUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'confirmationCode'?: number;
}

export const ProductStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type ProductStatusEnum = typeof ProductStatusEnum[keyof typeof ProductStatusEnum];
export const ProductChannelEnum = {
    AmazonUsa: 'AMAZON_USA',
    AmazonUk: 'AMAZON_UK',
    AmazonCa: 'AMAZON_CA',
    AmazonDe: 'AMAZON_DE',
    AmazonMx: 'AMAZON_MX',
    AmazonFr: 'AMAZON_FR',
    AmazonIt: 'AMAZON_IT',
    AmazonEs: 'AMAZON_ES',
    AmazonJp: 'AMAZON_JP',
    AmazonSg: 'AMAZON_SG',
    AmazonAe: 'AMAZON_AE',
    AmazonBr: 'AMAZON_BR',
    AmazonAu: 'AMAZON_AU',
    AmazonIn: 'AMAZON_IN',
    AmazonNl: 'AMAZON_NL',
    AmazonSa: 'AMAZON_SA',
    AmazonTr: 'AMAZON_TR',
    AmazonSe: 'AMAZON_SE',
    AmazonPl: 'AMAZON_PL',
    EtsyUsa: 'ETSY_USA',
    EtsyUk: 'ETSY_UK',
    Wallmart: 'WALLMART',
    Shopify: 'SHOPIFY'
} as const;

export type ProductChannelEnum = typeof ProductChannelEnum[keyof typeof ProductChannelEnum];
export const ProductLongestSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductLongestSideDimensionUnitEnum = typeof ProductLongestSideDimensionUnitEnum[keyof typeof ProductLongestSideDimensionUnitEnum];
export const ProductMedianSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductMedianSideDimensionUnitEnum = typeof ProductMedianSideDimensionUnitEnum[keyof typeof ProductMedianSideDimensionUnitEnum];
export const ProductShortSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductShortSideDimensionUnitEnum = typeof ProductShortSideDimensionUnitEnum[keyof typeof ProductShortSideDimensionUnitEnum];
export const ProductUnitWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductUnitWeightUnitEnum = typeof ProductUnitWeightUnitEnum[keyof typeof ProductUnitWeightUnitEnum];
export const ProductPackagingWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductPackagingWeightUnitEnum = typeof ProductPackagingWeightUnitEnum[keyof typeof ProductPackagingWeightUnitEnum];
export const ProductShippingWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductShippingWeightUnitEnum = typeof ProductShippingWeightUnitEnum[keyof typeof ProductShippingWeightUnitEnum];
export const ProductAmazonProductStatusEnum = {
    NoChange: 'NO_CHANGE',
    NewChange: 'NEW_CHANGE',
    Accepted: 'ACCEPTED',
    OnHold: 'ON_HOLD',
    InReview: 'IN_REVIEW'
} as const;

export type ProductAmazonProductStatusEnum = typeof ProductAmazonProductStatusEnum[keyof typeof ProductAmazonProductStatusEnum];
export const ProductPerUnitWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductPerUnitWeightUnitEnum = typeof ProductPerUnitWeightUnitEnum[keyof typeof ProductPerUnitWeightUnitEnum];

/**
 * 
 * @export
 * @interface ProductAcceptRejectDto
 */
export interface ProductAcceptRejectDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductAcceptRejectDto
     */
    'productIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ProductAcceptRejectDto
     */
    'action'?: string;
}
/**
 * 
 * @export
 * @interface ProductChangeLog
 */
export interface ProductChangeLog {
    /**
     * 
     * @type {number}
     * @memberof ProductChangeLog
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductChangeLog
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductChangeLog
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductChangeLog
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductChangeLog
     */
    'productChangeSource'?: ProductChangeLogProductChangeSourceEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductChangeLog
     */
    'unixDateTimeOfChange'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductChangeLog
     */
    'dateTimeOfChange'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductChangeLog
     */
    'marketplace'?: ProductChangeLogMarketplaceEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductChangeLog
     */
    'longestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductChangeLog
     */
    'longestSideDimensionUnit'?: ProductChangeLogLongestSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductChangeLog
     */
    'medianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductChangeLog
     */
    'medianSideDimensionUnit'?: ProductChangeLogMedianSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductChangeLog
     */
    'shortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductChangeLog
     */
    'shortSideDimensionUnit'?: ProductChangeLogShortSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductChangeLog
     */
    'unitWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductChangeLog
     */
    'unitWeightUnit'?: ProductChangeLogUnitWeightUnitEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductChangeLog
     */
    'changeDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductChangeLog
     */
    'productSizeTier'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductChangeLog
     */
    'perUnitWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductChangeLog
     */
    'perUnitWeightUnit'?: ProductChangeLogPerUnitWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductChangeLog
     */
    'estReferralFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductChangeLog
     */
    'fbaFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductChangeLog
     */
    'estFeeTotal'?: number;
}

export const ProductChangeLogProductChangeSourceEnum = {
    SellervueUser: 'SELLERVUE_USER',
    AmazonProfitPreview: 'AMAZON_PROFIT_PREVIEW',
    CsvImport: 'CSV_IMPORT',
    AmazonImport: 'AMAZON_IMPORT'
} as const;

export type ProductChangeLogProductChangeSourceEnum = typeof ProductChangeLogProductChangeSourceEnum[keyof typeof ProductChangeLogProductChangeSourceEnum];
export const ProductChangeLogMarketplaceEnum = {
    AmazonUsa: 'AMAZON_USA',
    AmazonUk: 'AMAZON_UK',
    AmazonCa: 'AMAZON_CA',
    AmazonDe: 'AMAZON_DE',
    AmazonMx: 'AMAZON_MX',
    AmazonFr: 'AMAZON_FR',
    AmazonIt: 'AMAZON_IT',
    AmazonEs: 'AMAZON_ES',
    AmazonJp: 'AMAZON_JP',
    AmazonSg: 'AMAZON_SG',
    AmazonAe: 'AMAZON_AE',
    AmazonBr: 'AMAZON_BR',
    AmazonAu: 'AMAZON_AU',
    AmazonIn: 'AMAZON_IN',
    AmazonNl: 'AMAZON_NL',
    AmazonSa: 'AMAZON_SA',
    AmazonTr: 'AMAZON_TR',
    AmazonSe: 'AMAZON_SE',
    AmazonPl: 'AMAZON_PL',
    EtsyUsa: 'ETSY_USA',
    EtsyUk: 'ETSY_UK',
    Wallmart: 'WALLMART',
    Shopify: 'SHOPIFY'
} as const;

export type ProductChangeLogMarketplaceEnum = typeof ProductChangeLogMarketplaceEnum[keyof typeof ProductChangeLogMarketplaceEnum];
export const ProductChangeLogLongestSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductChangeLogLongestSideDimensionUnitEnum = typeof ProductChangeLogLongestSideDimensionUnitEnum[keyof typeof ProductChangeLogLongestSideDimensionUnitEnum];
export const ProductChangeLogMedianSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductChangeLogMedianSideDimensionUnitEnum = typeof ProductChangeLogMedianSideDimensionUnitEnum[keyof typeof ProductChangeLogMedianSideDimensionUnitEnum];
export const ProductChangeLogShortSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductChangeLogShortSideDimensionUnitEnum = typeof ProductChangeLogShortSideDimensionUnitEnum[keyof typeof ProductChangeLogShortSideDimensionUnitEnum];
export const ProductChangeLogUnitWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductChangeLogUnitWeightUnitEnum = typeof ProductChangeLogUnitWeightUnitEnum[keyof typeof ProductChangeLogUnitWeightUnitEnum];
export const ProductChangeLogPerUnitWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductChangeLogPerUnitWeightUnitEnum = typeof ProductChangeLogPerUnitWeightUnitEnum[keyof typeof ProductChangeLogPerUnitWeightUnitEnum];

/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'skuAlias'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'fnSku'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'asin'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'status'?: ProductDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'tag'?: string;
    /**
     * 
     * @type {Array<ProductSupplier>}
     * @memberof ProductDto
     */
    'productSuppliers'?: Array<ProductSupplier>;
    /**
     * 
     * @type {Group}
     * @memberof ProductDto
     */
    'group'?: Group;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'fulfilledBy'?: string;
    /**
     * 
     * @type {Brand}
     * @memberof ProductDto
     */
    'brand'?: Brand;
    /**
     * 
     * @type {Brand}
     * @memberof ProductDto
     */
    'reBrand'?: Brand;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'userId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    'isVariant'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    'hasVariants'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'rootProductId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'variantNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'channel'?: ProductDtoChannelEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'longestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'longestSideDimensionUnit'?: ProductDtoLongestSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'medianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'medianSideDimensionUnit'?: ProductDtoMedianSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'shortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'shortSideDimensionUnit'?: ProductDtoShortSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'unitWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'unitWeightUnit'?: ProductDtoUnitWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'packagingWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'packagingWeightUnit'?: ProductDtoPackagingWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'shippingWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'shippingWeightUnit'?: ProductDtoShippingWeightUnitEnum;
    /**
     * 
     * @type {Array<Product>}
     * @memberof ProductDto
     */
    'products'?: Array<Product>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    'isBundle'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    'changedInAmazon'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'amazonFees'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'amazonProductStatus'?: ProductDtoAmazonProductStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'changeDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'listingPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'productSizeTier'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'perUnitWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'perUnitWeightUnit'?: ProductDtoPerUnitWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'landedUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'costMargin'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'estReferralFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'estReferralFeePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'fbaFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'fbaFeePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'estFeeTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'estFeeTotalPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'estVariableClosingFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'estVariableClosingFeePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'estOrderHandlingFeePerOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'estOrderHandlingFeePerOrderPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'estPickPackFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'estPickPackFeePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'estWeightHandlingFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'estWeightHandlingFeePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'grossProfit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'grossMarginPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'numberOfGoodsOnOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'numberOfGoodsInTransit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'totalValueOfGoodsOnOrderAndInTransit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'session'?: string;
}

export const ProductDtoStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type ProductDtoStatusEnum = typeof ProductDtoStatusEnum[keyof typeof ProductDtoStatusEnum];
export const ProductDtoChannelEnum = {
    AmazonUsa: 'AMAZON_USA',
    AmazonUk: 'AMAZON_UK',
    AmazonCa: 'AMAZON_CA',
    AmazonDe: 'AMAZON_DE',
    AmazonMx: 'AMAZON_MX',
    AmazonFr: 'AMAZON_FR',
    AmazonIt: 'AMAZON_IT',
    AmazonEs: 'AMAZON_ES',
    AmazonJp: 'AMAZON_JP',
    AmazonSg: 'AMAZON_SG',
    AmazonAe: 'AMAZON_AE',
    AmazonBr: 'AMAZON_BR',
    AmazonAu: 'AMAZON_AU',
    AmazonIn: 'AMAZON_IN',
    AmazonNl: 'AMAZON_NL',
    AmazonSa: 'AMAZON_SA',
    AmazonTr: 'AMAZON_TR',
    AmazonSe: 'AMAZON_SE',
    AmazonPl: 'AMAZON_PL',
    EtsyUsa: 'ETSY_USA',
    EtsyUk: 'ETSY_UK',
    Wallmart: 'WALLMART',
    Shopify: 'SHOPIFY'
} as const;

export type ProductDtoChannelEnum = typeof ProductDtoChannelEnum[keyof typeof ProductDtoChannelEnum];
export const ProductDtoLongestSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductDtoLongestSideDimensionUnitEnum = typeof ProductDtoLongestSideDimensionUnitEnum[keyof typeof ProductDtoLongestSideDimensionUnitEnum];
export const ProductDtoMedianSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductDtoMedianSideDimensionUnitEnum = typeof ProductDtoMedianSideDimensionUnitEnum[keyof typeof ProductDtoMedianSideDimensionUnitEnum];
export const ProductDtoShortSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductDtoShortSideDimensionUnitEnum = typeof ProductDtoShortSideDimensionUnitEnum[keyof typeof ProductDtoShortSideDimensionUnitEnum];
export const ProductDtoUnitWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductDtoUnitWeightUnitEnum = typeof ProductDtoUnitWeightUnitEnum[keyof typeof ProductDtoUnitWeightUnitEnum];
export const ProductDtoPackagingWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductDtoPackagingWeightUnitEnum = typeof ProductDtoPackagingWeightUnitEnum[keyof typeof ProductDtoPackagingWeightUnitEnum];
export const ProductDtoShippingWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductDtoShippingWeightUnitEnum = typeof ProductDtoShippingWeightUnitEnum[keyof typeof ProductDtoShippingWeightUnitEnum];
export const ProductDtoAmazonProductStatusEnum = {
    NoChange: 'NO_CHANGE',
    NewChange: 'NEW_CHANGE',
    Accepted: 'ACCEPTED',
    OnHold: 'ON_HOLD',
    InReview: 'IN_REVIEW'
} as const;

export type ProductDtoAmazonProductStatusEnum = typeof ProductDtoAmazonProductStatusEnum[keyof typeof ProductDtoAmazonProductStatusEnum];
export const ProductDtoPerUnitWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductDtoPerUnitWeightUnitEnum = typeof ProductDtoPerUnitWeightUnitEnum[keyof typeof ProductDtoPerUnitWeightUnitEnum];

/**
 * 
 * @export
 * @interface ProductPatchDto
 */
export interface ProductPatchDto {
    /**
     * 
     * @type {string}
     * @memberof ProductPatchDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductPatchDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductPatchDto
     */
    'skuAlias'?: string;
}
/**
 * 
 * @export
 * @interface ProductSummaryDto
 */
export interface ProductSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ProductSummaryDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSummaryDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSummaryDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSummaryDto
     */
    'skuAlias'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductSummaryDto
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSummaryDto
     */
    'accountId'?: number;
}
/**
 * 
 * @export
 * @interface ProductSupplier
 */
export interface ProductSupplier {
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'countryOfOrigin'?: ProductSupplierCountryOfOriginEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'productionLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'inTransitLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'totalLeadTimeInDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'supplierTermsInDays'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'shippingMethod'?: ProductSupplierShippingMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'material'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'hsCodes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'tariffRates'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'orderQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'unitsPerCase'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'totalCases'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'packagingLongestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'packagingLongestSideDimensionUnit'?: ProductSupplierPackagingLongestSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'packagingMedianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'packagingMedianSideDimensionUnit'?: ProductSupplierPackagingMedianSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'packagingShortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'packagingShortSideDimensionUnit'?: ProductSupplierPackagingShortSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'packagingUnitWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'packagingUnitWeightUnit'?: ProductSupplierPackagingUnitWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'caseLongestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'caseLongestSideDimensionUnit'?: ProductSupplierCaseLongestSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'caseMedianSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'caseMedianSideDimensionUnit'?: ProductSupplierCaseMedianSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'caseShortSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'caseShortSideDimensionUnit'?: ProductSupplierCaseShortSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'caseGrossWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'caseGrossWeightUnit'?: ProductSupplierCaseGrossWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'cubicMetres'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'costPerCubicMetre'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'totalGrossWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSupplier
     */
    'totalGrossWeightUnit'?: ProductSupplierTotalGrossWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'id'?: number;
    /**
     * 
     * @type {Product}
     * @memberof ProductSupplier
     */
    'product'?: Product;
    /**
     * 
     * @type {Supplier}
     * @memberof ProductSupplier
     */
    'supplier'?: Supplier;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'leadTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'transientSupplierId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSupplier
     */
    'transientProductId'?: number;
}

export const ProductSupplierCountryOfOriginEnum = {
    Us: 'US',
    Ca: 'CA',
    Gb: 'GB',
    Cn: 'CN',
    Mx: 'MX',
    Af: 'AF',
    Ax: 'AX',
    Al: 'AL',
    Dz: 'DZ',
    As: 'AS',
    Ad: 'AD',
    Ao: 'AO',
    Ai: 'AI',
    Aq: 'AQ',
    Ag: 'AG',
    Ar: 'AR',
    Am: 'AM',
    Aw: 'AW',
    Au: 'AU',
    At: 'AT',
    Az: 'AZ',
    Bs: 'BS',
    Bh: 'BH',
    Bd: 'BD',
    Bb: 'BB',
    By: 'BY',
    Be: 'BE',
    Bz: 'BZ',
    Bj: 'BJ',
    Bm: 'BM',
    Bt: 'BT',
    Bo: 'BO',
    Bq: 'BQ',
    Ba: 'BA',
    Bw: 'BW',
    Bv: 'BV',
    Br: 'BR',
    Io: 'IO',
    Bn: 'BN',
    Bg: 'BG',
    Bf: 'BF',
    Bi: 'BI',
    Cv: 'CV',
    Kh: 'KH',
    Cm: 'CM',
    Ky: 'KY',
    Cf: 'CF',
    Td: 'TD',
    Cl: 'CL',
    Cx: 'CX',
    Cc: 'CC',
    Co: 'CO',
    Km: 'KM',
    Cg: 'CG',
    Cd: 'CD',
    Ck: 'CK',
    Cr: 'CR',
    Ci: 'CI',
    Hr: 'HR',
    Cu: 'CU',
    Cw: 'CW',
    Cy: 'CY',
    Cz: 'CZ',
    Dk: 'DK',
    Dj: 'DJ',
    Dm: 'DM',
    Do: 'DO',
    Ec: 'EC',
    Eg: 'EG',
    Sv: 'SV',
    Gq: 'GQ',
    Er: 'ER',
    Ee: 'EE',
    Sz: 'SZ',
    Et: 'ET',
    Fk: 'FK',
    Fo: 'FO',
    Fj: 'FJ',
    Fi: 'FI',
    Fr: 'FR',
    Gf: 'GF',
    Pf: 'PF',
    Tf: 'TF',
    Ga: 'GA',
    Gm: 'GM',
    Ge: 'GE',
    De: 'DE',
    Gh: 'GH',
    Gi: 'GI',
    Gr: 'GR',
    Gl: 'GL',
    Gd: 'GD',
    Gp: 'GP',
    Gu: 'GU',
    Gt: 'GT',
    Gg: 'GG',
    Gn: 'GN',
    Gw: 'GW',
    Gy: 'GY',
    Ht: 'HT',
    Hm: 'HM',
    Va: 'VA',
    Hn: 'HN',
    Hk: 'HK',
    Hu: 'HU',
    Is: 'IS',
    In: 'IN',
    Id: 'ID',
    Ir: 'IR',
    Iq: 'IQ',
    Ie: 'IE',
    Im: 'IM',
    Il: 'IL',
    It: 'IT',
    Jm: 'JM',
    Jp: 'JP',
    Je: 'JE',
    Jo: 'JO',
    Kz: 'KZ',
    Ke: 'KE',
    Ki: 'KI',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Kg: 'KG',
    La: 'LA',
    Lv: 'LV',
    Lb: 'LB',
    Ls: 'LS',
    Lr: 'LR',
    Ly: 'LY',
    Li: 'LI',
    Lt: 'LT',
    Lu: 'LU',
    Mo: 'MO',
    Mg: 'MG',
    Mw: 'MW',
    My: 'MY',
    Mv: 'MV',
    Ml: 'ML',
    Mt: 'MT',
    Mh: 'MH',
    Mq: 'MQ',
    Mr: 'MR',
    Mu: 'MU',
    Yt: 'YT',
    Fm: 'FM',
    Md: 'MD',
    Mc: 'MC',
    Mn: 'MN',
    Me: 'ME',
    Ms: 'MS',
    Ma: 'MA',
    Mz: 'MZ',
    Mm: 'MM',
    Na: 'NA',
    Nr: 'NR',
    Np: 'NP',
    Nl: 'NL',
    Nc: 'NC',
    Nz: 'NZ',
    Ni: 'NI',
    Ne: 'NE',
    Ng: 'NG',
    Nu: 'NU',
    Nf: 'NF',
    Mk: 'MK',
    Mp: 'MP',
    No: 'NO',
    Om: 'OM',
    Pk: 'PK',
    Pw: 'PW',
    Ps: 'PS',
    Pa: 'PA',
    Pg: 'PG',
    Py: 'PY',
    Pe: 'PE',
    Ph: 'PH',
    Pn: 'PN',
    Pl: 'PL',
    Pt: 'PT',
    Pr: 'PR',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Ru: 'RU',
    Rw: 'RW',
    Bl: 'BL',
    Sh: 'SH',
    Kn: 'KN',
    Lc: 'LC',
    Mf: 'MF',
    Pm: 'PM',
    Vc: 'VC',
    Ws: 'WS',
    Sm: 'SM',
    St: 'ST',
    Sa: 'SA',
    Sn: 'SN',
    Rs: 'RS',
    Sc: 'SC',
    Sl: 'SL',
    Sg: 'SG',
    Sx: 'SX',
    Sk: 'SK',
    Si: 'SI',
    Sb: 'SB',
    So: 'SO',
    Za: 'ZA',
    Gs: 'GS',
    Ss: 'SS',
    Es: 'ES',
    Lk: 'LK',
    Sd: 'SD',
    Sr: 'SR',
    Sj: 'SJ',
    Se: 'SE',
    Ch: 'CH',
    Sy: 'SY',
    Tw: 'TW',
    Tj: 'TJ',
    Tz: 'TZ',
    Th: 'TH',
    Tl: 'TL',
    Tg: 'TG',
    Tk: 'TK',
    To: 'TO',
    Tt: 'TT',
    Tn: 'TN',
    Tr: 'TR',
    Tm: 'TM',
    Tc: 'TC',
    Tv: 'TV',
    Ug: 'UG',
    Ua: 'UA',
    Ae: 'AE',
    Um: 'UM',
    Uy: 'UY',
    Uz: 'UZ',
    Vu: 'VU',
    Ve: 'VE',
    Vn: 'VN',
    Vg: 'VG',
    Vi: 'VI',
    Wf: 'WF',
    Eh: 'EH',
    Ye: 'YE',
    Zm: 'ZM',
    Zw: 'ZW'
} as const;

export type ProductSupplierCountryOfOriginEnum = typeof ProductSupplierCountryOfOriginEnum[keyof typeof ProductSupplierCountryOfOriginEnum];
export const ProductSupplierShippingMethodEnum = {
    Air: 'AIR',
    Sea: 'SEA',
    Land: 'LAND'
} as const;

export type ProductSupplierShippingMethodEnum = typeof ProductSupplierShippingMethodEnum[keyof typeof ProductSupplierShippingMethodEnum];
export const ProductSupplierPackagingLongestSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductSupplierPackagingLongestSideDimensionUnitEnum = typeof ProductSupplierPackagingLongestSideDimensionUnitEnum[keyof typeof ProductSupplierPackagingLongestSideDimensionUnitEnum];
export const ProductSupplierPackagingMedianSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductSupplierPackagingMedianSideDimensionUnitEnum = typeof ProductSupplierPackagingMedianSideDimensionUnitEnum[keyof typeof ProductSupplierPackagingMedianSideDimensionUnitEnum];
export const ProductSupplierPackagingShortSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductSupplierPackagingShortSideDimensionUnitEnum = typeof ProductSupplierPackagingShortSideDimensionUnitEnum[keyof typeof ProductSupplierPackagingShortSideDimensionUnitEnum];
export const ProductSupplierPackagingUnitWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductSupplierPackagingUnitWeightUnitEnum = typeof ProductSupplierPackagingUnitWeightUnitEnum[keyof typeof ProductSupplierPackagingUnitWeightUnitEnum];
export const ProductSupplierCaseLongestSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductSupplierCaseLongestSideDimensionUnitEnum = typeof ProductSupplierCaseLongestSideDimensionUnitEnum[keyof typeof ProductSupplierCaseLongestSideDimensionUnitEnum];
export const ProductSupplierCaseMedianSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductSupplierCaseMedianSideDimensionUnitEnum = typeof ProductSupplierCaseMedianSideDimensionUnitEnum[keyof typeof ProductSupplierCaseMedianSideDimensionUnitEnum];
export const ProductSupplierCaseShortSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM',
    M: 'M',
    Ft: 'FT'
} as const;

export type ProductSupplierCaseShortSideDimensionUnitEnum = typeof ProductSupplierCaseShortSideDimensionUnitEnum[keyof typeof ProductSupplierCaseShortSideDimensionUnitEnum];
export const ProductSupplierCaseGrossWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductSupplierCaseGrossWeightUnitEnum = typeof ProductSupplierCaseGrossWeightUnitEnum[keyof typeof ProductSupplierCaseGrossWeightUnitEnum];
export const ProductSupplierTotalGrossWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type ProductSupplierTotalGrossWeightUnitEnum = typeof ProductSupplierTotalGrossWeightUnitEnum[keyof typeof ProductSupplierTotalGrossWeightUnitEnum];

/**
 * 
 * @export
 * @interface ProductVariantDto
 */
export interface ProductVariantDto {
    /**
     * 
     * @type {number}
     * @memberof ProductVariantDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVariantDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariantDto
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariantDto
     */
    'skuAlias'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVariantDto
     */
    'status'?: ProductVariantDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductVariantDto
     */
    'tag'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductVariantDto
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariantDto
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariantDto
     */
    'landedUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariantDto
     */
    'factoryUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariantDto
     */
    'inboundUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductVariantDto
     */
    'weightedAverageUnitCost'?: number;
}

export const ProductVariantDtoStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type ProductVariantDtoStatusEnum = typeof ProductVariantDtoStatusEnum[keyof typeof ProductVariantDtoStatusEnum];

/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    'open'?: boolean;
    /**
     * 
     * @type {File}
     * @memberof Resource
     */
    'file'?: File;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    'readable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'uri'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'description'?: string;
    /**
     * 
     * @type {object}
     * @memberof Resource
     */
    'inputStream'?: object;
}
/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'unsorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface Supplier
 */
export interface Supplier {
    /**
     * 
     * @type {number}
     * @memberof Supplier
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'telephoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'companyName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Supplier
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Supplier
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'currencyCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'countryCode'?: string;
}

/**
 * BrandControllerApi - axios parameter creator
 * @export
 */
export const BrandControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {BrandDto} [brandDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBrand: async (userId: number, brandDto?: BrandDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createBrand', 'userId', userId)
            const localVarPath = `/brands/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBrand: async (userId: number, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteBrand', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBrand', 'id', id)
            const localVarPath = `/brands/{userId}/{id}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBrands: async (pageable: Pageable, userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getAllBrands', 'pageable', pageable)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllBrands', 'userId', userId)
            const localVarPath = `/brands/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandById: async (userId: number, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getBrandById', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBrandById', 'id', id)
            const localVarPath = `/brands/{userId}/{id}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {BrandDto} [brandDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceBrand: async (userId: number, id: number, brandDto?: BrandDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('replaceBrand', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('replaceBrand', 'id', id)
            const localVarPath = `/brands/{userId}/{id}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brandDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrandControllerApi - functional programming interface
 * @export
 */
export const BrandControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrandControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {BrandDto} [brandDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBrand(userId: number, brandDto?: BrandDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Brand>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBrand(userId, brandDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BrandControllerApi.createBrand']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBrand(userId: number, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBrand(userId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BrandControllerApi.deleteBrand']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBrands(pageable: Pageable, userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageBrand>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBrands(pageable, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BrandControllerApi.getAllBrands']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrandById(userId: number, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Brand>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandById(userId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BrandControllerApi.getBrandById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {BrandDto} [brandDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceBrand(userId: number, id: number, brandDto?: BrandDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Brand>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceBrand(userId, id, brandDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BrandControllerApi.replaceBrand']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BrandControllerApi - factory interface
 * @export
 */
export const BrandControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrandControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {BrandDto} [brandDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBrand(userId: number, brandDto?: BrandDto, options?: any): AxiosPromise<Brand> {
            return localVarFp.createBrand(userId, brandDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBrand(userId: number, id: number, options?: any): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.deleteBrand(userId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBrands(pageable: Pageable, userId: number, options?: any): AxiosPromise<PageBrand> {
            return localVarFp.getAllBrands(pageable, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandById(userId: number, id: number, options?: any): AxiosPromise<Brand> {
            return localVarFp.getBrandById(userId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {BrandDto} [brandDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceBrand(userId: number, id: number, brandDto?: BrandDto, options?: any): AxiosPromise<Brand> {
            return localVarFp.replaceBrand(userId, id, brandDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrandControllerApi - object-oriented interface
 * @export
 * @class BrandControllerApi
 * @extends {BaseAPI}
 */
export class BrandControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {BrandDto} [brandDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandControllerApi
     */
    public createBrand(userId: number, brandDto?: BrandDto, options?: RawAxiosRequestConfig) {
        return BrandControllerApiFp(this.configuration).createBrand(userId, brandDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandControllerApi
     */
    public deleteBrand(userId: number, id: number, options?: RawAxiosRequestConfig) {
        return BrandControllerApiFp(this.configuration).deleteBrand(userId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandControllerApi
     */
    public getAllBrands(pageable: Pageable, userId: number, options?: RawAxiosRequestConfig) {
        return BrandControllerApiFp(this.configuration).getAllBrands(pageable, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandControllerApi
     */
    public getBrandById(userId: number, id: number, options?: RawAxiosRequestConfig) {
        return BrandControllerApiFp(this.configuration).getBrandById(userId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} id 
     * @param {BrandDto} [brandDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandControllerApi
     */
    public replaceBrand(userId: number, id: number, brandDto?: BrandDto, options?: RawAxiosRequestConfig) {
        return BrandControllerApiFp(this.configuration).replaceBrand(userId, id, brandDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CsvControllerApi - axios parameter creator
 * @export
 */
export const CsvControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {ImportProductCsvRequest} [importProductCsvRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importProductCsv: async (userId: number, importProductCsvRequest?: ImportProductCsvRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('importProductCsv', 'userId', userId)
            const localVarPath = `/products/{userId}/csv`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importProductCsvRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCsvTemplate: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('retrieveCsvTemplate', 'userId', userId)
            const localVarPath = `/products/{userId}/csv-template`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CsvControllerApi - functional programming interface
 * @export
 */
export const CsvControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CsvControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {ImportProductCsvRequest} [importProductCsvRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importProductCsv(userId: number, importProductCsvRequest?: ImportProductCsvRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importProductCsv(userId, importProductCsvRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CsvControllerApi.importProductCsv']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveCsvTemplate(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCsvTemplate(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CsvControllerApi.retrieveCsvTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CsvControllerApi - factory interface
 * @export
 */
export const CsvControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CsvControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {ImportProductCsvRequest} [importProductCsvRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importProductCsv(userId: number, importProductCsvRequest?: ImportProductCsvRequest, options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.importProductCsv(userId, importProductCsvRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCsvTemplate(userId: number, options?: any): AxiosPromise<void> {
            return localVarFp.retrieveCsvTemplate(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CsvControllerApi - object-oriented interface
 * @export
 * @class CsvControllerApi
 * @extends {BaseAPI}
 */
export class CsvControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {ImportProductCsvRequest} [importProductCsvRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvControllerApi
     */
    public importProductCsv(userId: number, importProductCsvRequest?: ImportProductCsvRequest, options?: RawAxiosRequestConfig) {
        return CsvControllerApiFp(this.configuration).importProductCsv(userId, importProductCsvRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CsvControllerApi
     */
    public retrieveCsvTemplate(userId: number, options?: RawAxiosRequestConfig) {
        return CsvControllerApiFp(this.configuration).retrieveCsvTemplate(userId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MeasurementUnitsControllerApi - axios parameter creator
 * @export
 */
export const MeasurementUnitsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDimensionUnits: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllDimensionUnits', 'userId', userId)
            const localVarPath = `/products/{userId}/dimension-units`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWeightUnits: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllWeightUnits', 'userId', userId)
            const localVarPath = `/products/{userId}/weight-units`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeasurementUnitsControllerApi - functional programming interface
 * @export
 */
export const MeasurementUnitsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeasurementUnitsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDimensionUnits(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDimensionUnits(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MeasurementUnitsControllerApi.getAllDimensionUnits']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWeightUnits(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllWeightUnits(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MeasurementUnitsControllerApi.getAllWeightUnits']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MeasurementUnitsControllerApi - factory interface
 * @export
 */
export const MeasurementUnitsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeasurementUnitsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDimensionUnits(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getAllDimensionUnits(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWeightUnits(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getAllWeightUnits(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeasurementUnitsControllerApi - object-oriented interface
 * @export
 * @class MeasurementUnitsControllerApi
 * @extends {BaseAPI}
 */
export class MeasurementUnitsControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementUnitsControllerApi
     */
    public getAllDimensionUnits(userId: number, options?: RawAxiosRequestConfig) {
        return MeasurementUnitsControllerApiFp(this.configuration).getAllDimensionUnits(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementUnitsControllerApi
     */
    public getAllWeightUnits(userId: number, options?: RawAxiosRequestConfig) {
        return MeasurementUnitsControllerApiFp(this.configuration).getAllWeightUnits(userId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductAdminControllerApi - axios parameter creator
 * @export
 */
export const ProductAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSerialisedProductDataForAccount: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('downloadSerialisedProductDataForAccount', 'userId', userId)
            const localVarPath = `/admin/products/{userId}/vending`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixSupplierNamesAfterImportForUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fixSupplierNamesAfterImportForUser', 'userId', userId)
            const localVarPath = `/admin/products/{userId}/vending/fix-names`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllProducts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/products/sync/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProductsByUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('syncProductsByUser', 'userId', userId)
            const localVarPath = `/admin/products/{userId}/sync`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {ImportProductCsvRequest} [importProductCsvRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSerialisedProductDataForAccount: async (userId: number, importProductCsvRequest?: ImportProductCsvRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('uploadSerialisedProductDataForAccount', 'userId', userId)
            const localVarPath = `/admin/products/{userId}/vending`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importProductCsvRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductAdminControllerApi - functional programming interface
 * @export
 */
export const ProductAdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductAdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadSerialisedProductDataForAccount(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Resource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadSerialisedProductDataForAccount(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductAdminControllerApi.downloadSerialisedProductDataForAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fixSupplierNamesAfterImportForUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fixSupplierNamesAfterImportForUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductAdminControllerApi.fixSupplierNamesAfterImportForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAllProducts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAllProducts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductAdminControllerApi.syncAllProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncProductsByUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncProductsByUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductAdminControllerApi.syncProductsByUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {ImportProductCsvRequest} [importProductCsvRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadSerialisedProductDataForAccount(userId: number, importProductCsvRequest?: ImportProductCsvRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadSerialisedProductDataForAccount(userId, importProductCsvRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductAdminControllerApi.uploadSerialisedProductDataForAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductAdminControllerApi - factory interface
 * @export
 */
export const ProductAdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductAdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSerialisedProductDataForAccount(userId: number, options?: any): AxiosPromise<Resource> {
            return localVarFp.downloadSerialisedProductDataForAccount(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixSupplierNamesAfterImportForUser(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.fixSupplierNamesAfterImportForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllProducts(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.syncAllProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProductsByUser(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.syncProductsByUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {ImportProductCsvRequest} [importProductCsvRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSerialisedProductDataForAccount(userId: number, importProductCsvRequest?: ImportProductCsvRequest, options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.uploadSerialisedProductDataForAccount(userId, importProductCsvRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductAdminControllerApi - object-oriented interface
 * @export
 * @class ProductAdminControllerApi
 * @extends {BaseAPI}
 */
export class ProductAdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminControllerApi
     */
    public downloadSerialisedProductDataForAccount(userId: number, options?: RawAxiosRequestConfig) {
        return ProductAdminControllerApiFp(this.configuration).downloadSerialisedProductDataForAccount(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminControllerApi
     */
    public fixSupplierNamesAfterImportForUser(userId: number, options?: RawAxiosRequestConfig) {
        return ProductAdminControllerApiFp(this.configuration).fixSupplierNamesAfterImportForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminControllerApi
     */
    public syncAllProducts(options?: RawAxiosRequestConfig) {
        return ProductAdminControllerApiFp(this.configuration).syncAllProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminControllerApi
     */
    public syncProductsByUser(userId: number, options?: RawAxiosRequestConfig) {
        return ProductAdminControllerApiFp(this.configuration).syncProductsByUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {ImportProductCsvRequest} [importProductCsvRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAdminControllerApi
     */
    public uploadSerialisedProductDataForAccount(userId: number, importProductCsvRequest?: ImportProductCsvRequest, options?: RawAxiosRequestConfig) {
        return ProductAdminControllerApiFp(this.configuration).uploadSerialisedProductDataForAccount(userId, importProductCsvRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductControllerApi - axios parameter creator
 * @export
 */
export const ProductControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {ProductAcceptRejectDto} [productAcceptRejectDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptRejectAmazonChanges: async (userId: number, productAcceptRejectDto?: ProductAcceptRejectDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('acceptRejectAmazonChanges', 'userId', userId)
            const localVarPath = `/products/{userId}/accept-reject`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productAcceptRejectDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {ProductDto} [productDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (userId: number, productDto?: ProductDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createProduct', 'userId', userId)
            const localVarPath = `/products/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct: async (userId: number, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteProduct', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProduct', 'id', id)
            const localVarPath = `/products/{userId}/{id}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchProductChangeLogSources: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchProductChangeLogSources', 'userId', userId)
            const localVarPath = `/products/{userId}/changelog-sources`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchProductChangeLogsForProduct: async (userId: number, productId: number, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchProductChangeLogsForProduct', 'userId', userId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('fetchProductChangeLogsForProduct', 'productId', productId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('fetchProductChangeLogsForProduct', 'pageable', pageable)
            const localVarPath = `/products/{userId}/{productId}/changelog`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateNewVariantForProduct: async (userId: number, productId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('generateNewVariantForProduct', 'userId', userId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('generateNewVariantForProduct', 'productId', productId)
            const localVarPath = `/products/{userId}/{productId}/variants/new`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProducts: async (pageable: Pageable, userId: number, sku?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getAllProducts', 'pageable', pageable)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllProducts', 'userId', userId)
            const localVarPath = `/products/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllShippingMethods: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllShippingMethods', 'userId', userId)
            const localVarPath = `/products/{userId}/shipping-methods`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById: async (userId: number, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getProductById', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProductById', 'id', id)
            const localVarPath = `/products/{userId}/{id}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductCountryOfOriginOptions: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getProductCountryOfOriginOptions', 'userId', userId)
            const localVarPath = `/products/{userId}/countries`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} [dryRun] 
         * @param {ImportGoogleSheetDto} [importGoogleSheetDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importInvoiceGoogleSheet: async (userId: number, dryRun?: boolean, importGoogleSheetDto?: ImportGoogleSheetDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('importInvoiceGoogleSheet', 'userId', userId)
            const localVarPath = `/products/{userId}/import-from-invoice-google-sheet`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dryRun !== undefined) {
                localVarQueryParameter['dry-run'] = dryRun;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importGoogleSheetDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {ProductDto} [productDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceProduct: async (userId: number, id: number, productDto?: ProductDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('replaceProduct', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('replaceProduct', 'id', id)
            const localVarPath = `/products/{userId}/{id}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveVariantsForProduct: async (userId: number, productId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('retrieveVariantsForProduct', 'userId', userId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('retrieveVariantsForProduct', 'productId', productId)
            const localVarPath = `/products/{userId}/{productId}/variants`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification: async (userId: number, notificationDto?: NotificationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('sendNotification', 'userId', userId)
            const localVarPath = `/products/{userId}/notification`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {ProductPatchDto} [productPatchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct: async (userId: number, productId: number, productPatchDto?: ProductPatchDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateProduct', 'userId', userId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('updateProduct', 'productId', productId)
            const localVarPath = `/products/{userId}/{productId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productPatchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductControllerApi - functional programming interface
 * @export
 */
export const ProductControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {ProductAcceptRejectDto} [productAcceptRejectDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptRejectAmazonChanges(userId: number, productAcceptRejectDto?: ProductAcceptRejectDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptRejectAmazonChanges(userId, productAcceptRejectDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductControllerApi.acceptRejectAmazonChanges']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {ProductDto} [productDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProduct(userId: number, productDto?: ProductDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProduct(userId, productDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductControllerApi.createProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProduct(userId: number, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProduct(userId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductControllerApi.deleteProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchProductChangeLogSources(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchProductChangeLogSources(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductControllerApi.fetchProductChangeLogSources']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchProductChangeLogsForProduct(userId: number, productId: number, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageProductChangeLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchProductChangeLogsForProduct(userId, productId, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductControllerApi.fetchProductChangeLogsForProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateNewVariantForProduct(userId: number, productId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateNewVariantForProduct(userId, productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductControllerApi.generateNewVariantForProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProducts(pageable: Pageable, userId: number, sku?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProducts(pageable, userId, sku, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductControllerApi.getAllProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllShippingMethods(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllShippingMethods(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductControllerApi.getAllShippingMethods']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductById(userId: number, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductById(userId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductControllerApi.getProductById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductCountryOfOriginOptions(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductCountryOfOriginOptions(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductControllerApi.getProductCountryOfOriginOptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} [dryRun] 
         * @param {ImportGoogleSheetDto} [importGoogleSheetDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importInvoiceGoogleSheet(userId: number, dryRun?: boolean, importGoogleSheetDto?: ImportGoogleSheetDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductSummaryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importInvoiceGoogleSheet(userId, dryRun, importGoogleSheetDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductControllerApi.importInvoiceGoogleSheet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {ProductDto} [productDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceProduct(userId: number, id: number, productDto?: ProductDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceProduct(userId, id, productDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductControllerApi.replaceProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveVariantsForProduct(userId: number, productId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductVariantDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveVariantsForProduct(userId, productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductControllerApi.retrieveVariantsForProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotification(userId: number, notificationDto?: NotificationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNotification(userId, notificationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductControllerApi.sendNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {ProductPatchDto} [productPatchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProduct(userId: number, productId: number, productPatchDto?: ProductPatchDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProduct(userId, productId, productPatchDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductControllerApi.updateProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductControllerApi - factory interface
 * @export
 */
export const ProductControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {ProductAcceptRejectDto} [productAcceptRejectDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptRejectAmazonChanges(userId: number, productAcceptRejectDto?: ProductAcceptRejectDto, options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.acceptRejectAmazonChanges(userId, productAcceptRejectDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {ProductDto} [productDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(userId: number, productDto?: ProductDto, options?: any): AxiosPromise<Product> {
            return localVarFp.createProduct(userId, productDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct(userId: number, id: number, options?: any): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.deleteProduct(userId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchProductChangeLogSources(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.fetchProductChangeLogSources(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchProductChangeLogsForProduct(userId: number, productId: number, pageable: Pageable, options?: any): AxiosPromise<PageProductChangeLog> {
            return localVarFp.fetchProductChangeLogsForProduct(userId, productId, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateNewVariantForProduct(userId: number, productId: number, options?: any): AxiosPromise<Product> {
            return localVarFp.generateNewVariantForProduct(userId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {string} [sku] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProducts(pageable: Pageable, userId: number, sku?: string, options?: any): AxiosPromise<PageProduct> {
            return localVarFp.getAllProducts(pageable, userId, sku, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllShippingMethods(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getAllShippingMethods(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById(userId: number, id: number, options?: any): AxiosPromise<Product> {
            return localVarFp.getProductById(userId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductCountryOfOriginOptions(userId: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getProductCountryOfOriginOptions(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} [dryRun] 
         * @param {ImportGoogleSheetDto} [importGoogleSheetDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importInvoiceGoogleSheet(userId: number, dryRun?: boolean, importGoogleSheetDto?: ImportGoogleSheetDto, options?: any): AxiosPromise<Array<ProductSummaryDto>> {
            return localVarFp.importInvoiceGoogleSheet(userId, dryRun, importGoogleSheetDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} id 
         * @param {ProductDto} [productDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceProduct(userId: number, id: number, productDto?: ProductDto, options?: any): AxiosPromise<Product> {
            return localVarFp.replaceProduct(userId, id, productDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveVariantsForProduct(userId: number, productId: number, options?: any): AxiosPromise<Array<ProductVariantDto>> {
            return localVarFp.retrieveVariantsForProduct(userId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification(userId: number, notificationDto?: NotificationDto, options?: any): AxiosPromise<NotificationDto> {
            return localVarFp.sendNotification(userId, notificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} productId 
         * @param {ProductPatchDto} [productPatchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct(userId: number, productId: number, productPatchDto?: ProductPatchDto, options?: any): AxiosPromise<ProductSummaryDto> {
            return localVarFp.updateProduct(userId, productId, productPatchDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductControllerApi - object-oriented interface
 * @export
 * @class ProductControllerApi
 * @extends {BaseAPI}
 */
export class ProductControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {ProductAcceptRejectDto} [productAcceptRejectDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public acceptRejectAmazonChanges(userId: number, productAcceptRejectDto?: ProductAcceptRejectDto, options?: RawAxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).acceptRejectAmazonChanges(userId, productAcceptRejectDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {ProductDto} [productDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public createProduct(userId: number, productDto?: ProductDto, options?: RawAxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).createProduct(userId, productDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public deleteProduct(userId: number, id: number, options?: RawAxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).deleteProduct(userId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public fetchProductChangeLogSources(userId: number, options?: RawAxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).fetchProductChangeLogSources(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} productId 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public fetchProductChangeLogsForProduct(userId: number, productId: number, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).fetchProductChangeLogsForProduct(userId, productId, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public generateNewVariantForProduct(userId: number, productId: number, options?: RawAxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).generateNewVariantForProduct(userId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {number} userId 
     * @param {string} [sku] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public getAllProducts(pageable: Pageable, userId: number, sku?: string, options?: RawAxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).getAllProducts(pageable, userId, sku, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public getAllShippingMethods(userId: number, options?: RawAxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).getAllShippingMethods(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public getProductById(userId: number, id: number, options?: RawAxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).getProductById(userId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public getProductCountryOfOriginOptions(userId: string, options?: RawAxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).getProductCountryOfOriginOptions(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {boolean} [dryRun] 
     * @param {ImportGoogleSheetDto} [importGoogleSheetDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public importInvoiceGoogleSheet(userId: number, dryRun?: boolean, importGoogleSheetDto?: ImportGoogleSheetDto, options?: RawAxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).importInvoiceGoogleSheet(userId, dryRun, importGoogleSheetDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} id 
     * @param {ProductDto} [productDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public replaceProduct(userId: number, id: number, productDto?: ProductDto, options?: RawAxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).replaceProduct(userId, id, productDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public retrieveVariantsForProduct(userId: number, productId: number, options?: RawAxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).retrieveVariantsForProduct(userId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {NotificationDto} [notificationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public sendNotification(userId: number, notificationDto?: NotificationDto, options?: RawAxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).sendNotification(userId, notificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} productId 
     * @param {ProductPatchDto} [productPatchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public updateProduct(userId: number, productId: number, productPatchDto?: ProductPatchDto, options?: RawAxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).updateProduct(userId, productId, productPatchDto, options).then((request) => request(this.axios, this.basePath));
    }
}



