
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'

import type { Account, AccountSettings } from '@/client/users'
import { AccountAccountTierEnum } from '@/client/users'
import authRoutes from '@/modules/auth/modules/routes'
import type { Subscription } from '@/modules/billing/types/Subscription'
import type { IUser } from '@/modules/user/types'

import AppLogo from './AppLogo.vue'
import AppCreateNewDialog from './dialogs/AppCreateNewDialog.vue'

const namespaces = {
  user: 'user',
  accountSettings: 'accountSettings',
  app: 'app'
}

@Component({
  components: {
    AppLogo,
    AppCreateNewDialog
  }
})
export default class AppDrawer extends Vue {
  @State('darkMode', { namespace: namespaces.app }) darkMode?: boolean
  @State('subscription', { namespace: 'user' }) subscription!: Subscription
  @State('user', { namespace: namespaces.user }) user!: IUser
  @Getter('isAdmin', { namespace: namespaces.user }) isAdmin!: boolean
  @Getter('isViewer', { namespace: namespaces.user }) isViewer!: boolean
  @Getter('isSysAdmin', { namespace: namespaces.user }) isSysAdmin!: boolean
  @Getter('currentAccount', { namespace: namespaces.user })
    currentAccount!: Account

  @State('accountSettings', { namespace: namespaces.accountSettings })
    accountSettings!: AccountSettings

  @Action('getAccountSettings', { namespace: namespaces.accountSettings })
    getAccountSettings!: () => Promise<void>

  @Prop({ default: true }) readonly value!: boolean
  @Prop({ default: true }) readonly mini!: boolean

  get drawer () {
    return this.value
  }

  set drawer (value) {
    this.$emit('input', value)
  }

  showCreateDialog = false
  async beforeMount () {
    await Promise.all([
      this.accountSettings ? null : this.getAccountSettings()
    ])
  }

  get showSubRoute () {
    const res =
      !!this.user ||
      [
        '404',
        ...authRoutes.flatMap((r) => r.children || r).map(({ name }) => name)
      ].includes(this.$route.name || undefined)
    return res
  }

  get managementMenu () {
    return [
      {
        name: 'management',
        active: true,
        upgrade: !this.isManagementSectionEnabled,
        subItems: [
          {
            name: 'suppliers',
            icon: 'mdi-contacts-outline',
            disabled: !this.isManagementSectionEnabled
          },
          {
            name: 'products',
            icon: 'mdi-book-outline',
            disabled: !this.isManagementSectionEnabled
          },
          {
            name: 'invoices',
            icon: 'mdi-receipt-text-outline',
            disabled: !this.isManagementSectionEnabled
          },
          this.isOrdersAndShippingEnabled
            ? {
                name: 'orders',
                icon: 'mdi-package-variant',
                disabled: !this.isManagementSectionEnabled
              }
            : null,
          {
            name: 'bills',
            icon: 'mdi-currency-usd',
            disabled: !this.isManagementSectionEnabled
          }
        ].filter(Boolean)
      }
    ]
  }

  get reportingMenu () {
    return [
      {
        name: 'reporting',
        active: false,
        subItems: [
          { name: 'profit', icon: 'mdi-cash-multiple', disabled: true },
          { name: 'revenue', icon: 'mdi-finance', disabled: true },
          { name: 'turnover', icon: 'mdi-chart-pie', disabled: true },
          { name: 'statistics', icon: 'mdi-poll', disabled: true },
          { name: 'trends', icon: 'mdi-chart-line-variant', disabled: true }
        ]
      }
    ]
  }

  get settingsMenu () {
    return [
      {
        name: 'settings',
        active: true,
        subItems: [
          { name: 'profile', icon: 'mdi-account' },
          this.isAdmin && !this.isBillingLifetime
            ? { name: 'billing', icon: 'mdi-credit-card' }
            : null,
          this.isAdmin ? { name: 'users', icon: 'mdi-account-multiple' } : null,
          this.isAdmin
            ? { name: 'integrations', icon: 'mdi-power-plug' }
            : null,
          // this.isAdmin ? { name: 'accountSettings', icon: 'mdi-cog' } : null,
          { name: 'notifications', icon: 'mdi-bell-outline' }
          // { name: 'support', icon: 'mdi-lifebuoy', external: 'mailto:support@sellervue.com' }
        ].filter(Boolean)
      }
    ]
  }

  get system () {
    return [
      {
        name: 'system',
        active: true,
        subItems: [
          this.isSysAdmin
            ? { name: 'userManagement', icon: 'mdi-account-group' }
            : null
          // { name: 'notifications', icon: 'mdi-bell-outline' }
        ].filter(Boolean)
      }
    ]
  }

  get marketplacesMenu () {
    return [
      {
        name: 'marketplaces',
        active: true,
        subItems: [
          this.isFeeTrackerActive
            ? { name: 'feeTrackerPro', icon: 'mdi-chart-line' }
            : null,
          this.isSv1Enabled
            ? { name: 'marketplaceOverview', icon: 'mdi-chart-line' }
            : null,
          this.isSv1Enabled
            ? { name: 'ServiceFees', icon: 'mdi-chart-line' }
            : null,
          this.isSv1Enabled
            ? { name: 'ProductSales', icon: 'mdi-chart-line' }
            : null,
          this.isSv1Enabled
            ? { name: 'ProductProfitability', icon: 'mdi-chart-line' }
            : null,
          this.isSv1Enabled
            ? { name: 'ProductOrderMetrics', icon: 'mdi-chart-line' }
            : null
        ].filter(Boolean)
      }
    ]
  }

  get navigationItems () {
    return [
      ...this.managementMenu,
      ...this.marketplacesMenu,
      ...this.settingsMenu,
      ...this.system
    ]
  }

  get isOrdersAndShippingEnabled (): boolean {
    return this.accountSettings?.isOrdersAndShippingEnabled || false
  }

  get isBillingLifetime (): boolean {
    return (
      this.currentAccount.accountTier === AccountAccountTierEnum.Lifetime ||
      false
    )
  }

  get isManagementSectionEnabled (): boolean {
    return this.accountSettings?.isManagementSectionEnabled || false
  }

  get isFeeTrackerActive (): boolean {
    return this.accountSettings?.isFeeTrackerActive || false
  }

  get isSv1Enabled (): boolean {
    return this.accountSettings?.isSv1Enabled || false
  }

  get supportEmail (): string {
    return process.env?.VUE_APP_SUPPORT_EMAIL || ''
  }
}
