// src/axiosInstance.ts

import axios from 'axios'
import Vue from 'vue'

import router from '@/router'
import store from '@/store'
export const setupAxios = () => {
  axios.interceptors.response.use(
    (response) => {
      // If the response is successful, just return the response
      return response
    },
    (error) => {
      const path = router.currentRoute.path
      // If the status code is 403, redirect to the login page
      if (error?.response?.status === 401 || error?.response?.status === 403 || error?.response?.status === 498) {
        store.dispatch('user/clearUser', null, { root: true })
        store.dispatch('auth/clearToken', null, { root: true })
        const message = 'Session logged out, please log in again to continue'
        // store.dispatch('toasts/addError', { message })
        router.push({ name: 'authLogin', query: { path } }).catch(err => { console.log(err) })
        if (error?.response?.status !== 401) {
          Vue.prototype.$awn.info(message)
        }
      }
      // Return a rejected promise to handle the error in your application
      return Promise.reject(error)
    }
  )
}
