import * as Sentry from '@sentry/vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import api from '@/api'
import { DocumentControllerApi, FetchAllDocumentsForUserByEntityIdEntityTypeEnum, type PageDocument, type SendEmailDto } from '@/client/documents'
import { InvoiceControllerApi } from '@/client/invoices'
import { type Document, type PagePurchaseOrder, type ProductSheetImportDto, type PurchaseOrder, type PurchaseOrderCreateOrUpdateDto, PurchaseOrderPatchDtoPurchaseOrderStatusEnum, type PurchaseOrderPdfOptionsDto, PurchaseOrderProductImportDto, PurchaseOrderSummaryDto, PurchasingControllerApi } from '@/client/purchasing'
// import { i18n } from '@/plugins/i18n'

const { VUE_APP_BASE_URL } = process.env
const BASE_URL = VUE_APP_BASE_URL.slice(0, VUE_APP_BASE_URL.length - 1)

const purchasingControllerApi = new PurchasingControllerApi(undefined, BASE_URL)
const documentControllerApi = new DocumentControllerApi(undefined, BASE_URL)
const invoiceControllerApi = new InvoiceControllerApi(undefined, BASE_URL)

@Module({
  namespaced: true
})
export class PurchaseOrderModule extends VuexModule {
  purchaseOrders: PagePurchaseOrder | null = null
  purchaseOrder:PurchaseOrder|null = {}
  // internalPONumber:any
  @Mutation
  setPurchaseOrders (content: PagePurchaseOrder) {
    this.purchaseOrders = content
  }

  @Mutation
  setPO (content: PurchaseOrder) {
    this.purchaseOrder = content
  }

  // @Mutation
  // setInternalPoNumber (poNumber: string) {
  //   this.internalPONumber = poNumber
  // }

  @Action({ rawError: true })
  async getPurchaseOrder (opts: { page?: number, size?: number, search?: string, offset?: number, sort?: Array<any>}): Promise<void> {
    const userId = this.context.rootGetters['user/id']
    const token = this.context.rootGetters['auth/jwt']
    try {
      const data = await api.purchaseOrders.getPurchaseOrders(token, { ...opts, userId })
      this.context.commit('setPurchaseOrders', data)
    } catch (e:any) {
      this.context.commit('setPurchaseOrders', null)
      await this.context.dispatch('toasts/addError', e?.response?.data?.message || 'An error occurred trying to get purchase order', { root: true })
      Sentry.captureException(e)
    }
  }

  @Action({ rawError: true })
  async getNewPurchaseOrder (purchaseOrder:PurchaseOrderCreateOrUpdateDto): Promise<void> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      const { data } = await purchasingControllerApi.createPurchaseOrder(userId, purchaseOrder, requestOpts)
      this.context.commit('setPO', data)
    } catch (e:any) {
      // await this.context.dispatch('toasts/addError', e?.response?.data?.message || 'An error occurred while trying to create a purchase order', { root: true })
      Sentry.captureException(e)
      return Promise.reject(e)
    }
  }

  @Action({ rawError: true })
  async getPurchaseOrderById (id:number): Promise<void> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      const { data } = await purchasingControllerApi.fetchPurchaseOrdersForUser1(userId, id, requestOpts)
      this.context.commit('setPO', data)
    } catch (e:any) {
      // await this.context.dispatch('toasts/addError', e?.response?.data?.message || 'An error occurred while trying to create a purchase order', { root: true })
      Sentry.captureException(e)
      return Promise.reject(e)
    }
  }

  @Action({ rawError: true })
  async updatePurchaseOrderById (param:{ id: number, purchaseOrder: PurchaseOrderCreateOrUpdateDto }): Promise<void> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      const { data } = await purchasingControllerApi.updatePurchaseOrder(userId, param.id, param.purchaseOrder, requestOpts)
      this.context.commit('setPO', data)
    } catch (e:any) {
      // await this.context.dispatch('toasts/addError', e?.response?.data?.message || 'An error occurred while trying to create a purchase order', { root: true })
      Sentry.captureException(e)
      return Promise.reject(e)
    }
  }

  @Action({ rawError: true })
  async documentUploadForPO (params:{id:number, file:File}): Promise<Document> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const types:{ [key: string]: string } = {
        entityType: 'PURCHASE_ORDER',
        documentType: 'OTHER',
        entityId: params.id.toString()
      }
      return await api.documents.fileUpload(token, userId, params.file, types)
      // const { data } = await documentControllerApi.writeGcsFileResourceFromMultiPartForm(userId, types, requestOpts)
    } catch (e:any) {
      // await this.context.dispatch('toasts/addError', e?.response?.data?.message || 'An error occurred while trying to upload document', { root: true })
      Sentry.captureException(e)
      return Promise.reject(e)
    }
  }

  @Action({ rawError: true })
  async getUploadedFiles (params:{id:number, opts: { page?: number, size?: number}}): Promise<PageDocument | null> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      const entityType: FetchAllDocumentsForUserByEntityIdEntityTypeEnum = FetchAllDocumentsForUserByEntityIdEntityTypeEnum.PurchaseOrder
      const entityId = params.id
      const pageable = { page: params.opts.page, size: params.opts.size }
      const { data } = await documentControllerApi.fetchAllDocumentsForUserByEntityId(pageable, userId, entityType, entityId, requestOpts)
      return data
    } catch (e) {
      Sentry.captureException(e)
      return null
    }
  }

  @Action({ rawError: true })
  async deleteUploadedFiles (documentId:string) : Promise<void> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      await documentControllerApi.deleteGcsFileResource(userId, documentId, requestOpts)
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  @Action({ rawError: true })
  async getInternalPoNumber (): Promise<string> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      const { data } = await purchasingControllerApi.fetchNextInternalPONumberForUser(userId, requestOpts)
      return data
    } catch (e) {
      Sentry.captureException(e)
      return ''
    }
  }

  @Action({ rawError: true })
  async getPurchaseOrderDocument (params:{id:number, purchaseOrderPdfOptions: PurchaseOrderPdfOptionsDto}): Promise<Document> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }

      }
      const { data } = await purchasingControllerApi.generatePurchaseOrderPdfForUser(userId, params.id, params.purchaseOrderPdfOptions, requestOpts)
      return data
    } catch (e) {
      Sentry.captureException(e)
      return Promise.reject(e)
    }
  }

  @Action({ rawError: true })
  async sendEmailWithAttachments (sendEmailDto: SendEmailDto): Promise<string> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }

      }
      return (await documentControllerApi.sendEmailWithAttachments(userId, sendEmailDto, requestOpts)).data
    } catch (e) {
      Sentry.captureException(e)
      return Promise.reject(e)
    }
  }

  @Action({ rawError: true })
  async getDownloadDocumentById (id:string) :Promise<any> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      return await api.purchaseOrders.getPODocumentById(token, userId, id)
    } catch (e) {
      Sentry.captureException(e)
      return Promise.reject(e)
    }
  }

  // @Action({ rawError: true })
  // async getPurchaseOrdersSummary (opts: { page?: number, size?: number, search?: string, offset?: number, sort?: Array<any>}): Promise<Array<PurchaseOrderSummaryDto>> {
  //   const userId = this.context.rootGetters['user/id']
  //   const token = this.context.rootGetters['auth/jwt']
  //   try {
  //     return await api.purchaseOrders.getPurchaseOrdersSummary(token, { ...opts, userId })
  //   } catch (e:any) {
  //     // await this.context.dispatch('toasts/addError', e?.response?.data?.message || 'An error occurred trying to get purchase order', { root: true })
  //     Sentry.captureException(e)
  //     return Promise.reject(e)
  //   }
  // }
    @Action({ rawError: true })
  async getPurchaseOrdersSummary (opts: { purchaseOrderStatuses?: string, search?: string, includePaired?: string}): Promise<Array<PurchaseOrderSummaryDto>> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      const { data } = await invoiceControllerApi.fetchPurchaseOrderSummariesForUser(userId, opts.purchaseOrderStatuses, opts.search, opts.includePaired, requestOpts)
      return data
    } catch (e) {
      Sentry.captureException(e)
      return Promise.reject(e)
    }
  }

  @Action({ rawError: true })
    async purchaseOrderPatchForUpdatePOStatus (id:number) :Promise<void> {
      const token = this.context.rootGetters['auth/jwt']
      const userId = this.context.rootGetters['user/id']
      try {
        const requestOpts = {
          headers: { Authorization: `Bearer ${token}` }
        }
        await purchasingControllerApi.updatePurchaseOrderValuesForUser(userId, id, { purchaseOrderStatus: PurchaseOrderPatchDtoPurchaseOrderStatusEnum.Open }, requestOpts)
        return Promise.resolve()
      } catch (e) {
        Sentry.captureException(e)
        return Promise.reject(e)
      }
    }

  @Action({ rawError: true })
  async checkGoogleSheetDataForPurchaseOrderImport (url:string) :Promise<PurchaseOrderProductImportDto> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      const { data } = await purchasingControllerApi.checkGoogleSheetDataForPurchaseOrderImport(userId, url, requestOpts)
      return data
    } catch (e) {
      Sentry.captureException(e)
      return Promise.reject(e)
    }
  }

  @Action({ rawError: true })
  async importPurchaseOrdersFromGoogleSheet (itemsForImport: Array<ProductSheetImportDto>) :Promise<Array<PurchaseOrder>> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      const { data } = await purchasingControllerApi.importPurchaseOrdersFromGoogleSheet(userId, { itemsForImport }, false, requestOpts)
      return data
    } catch (e) {
      Sentry.captureException(e)
      return Promise.reject(e)
    }
  }
}
