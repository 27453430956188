/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'accountTier'?: AccountAccountTierEnum;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'tierExpiryDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    'isCurrent'?: boolean;
    /**
     * 
     * @type {Array<User>}
     * @memberof Account
     */
    'users'?: Array<User>;
    /**
     * 
     * @type {Array<Role>}
     * @memberof Account
     */
    'currentUserRoles'?: Array<Role>;
    /**
     * 
     * @type {AccountUserSettings}
     * @memberof Account
     */
    'currentAccountUserSettings'?: AccountUserSettings;
    /**
     * 
     * @type {AccountUser}
     * @memberof Account
     */
    'currentAccountUser'?: AccountUser;
    /**
     * 
     * @type {AccountSettings}
     * @memberof Account
     */
    'accountSettings'?: AccountSettings;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'unixTierExpiryDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'subscriptionId'?: number;
}

export const AccountAccountTierEnum = {
    Trial14: 'TRIAL_14',
    Monthly: 'MONTHLY',
    Annual: 'ANNUAL',
    Biennial: 'BIENNIAL',
    FeeTracker: 'FEE_TRACKER',
    FreeInvitee: 'FREE_INVITEE',
    SvBetaTrial1: 'SV_BETA_TRIAL_1',
    FeeTrackerMonthly: 'FEE_TRACKER_MONTHLY',
    FeeTrackerAnnual: 'FEE_TRACKER_ANNUAL',
    VipServicesMonthly: 'VIP_SERVICES_MONTHLY',
    VipServicesAnnual: 'VIP_SERVICES_ANNUAL',
    FreeAccount: 'FREE_ACCOUNT',
    Lifetime: 'LIFETIME',
    Trial21: 'TRIAL_21'
} as const;

export type AccountAccountTierEnum = typeof AccountAccountTierEnum[keyof typeof AccountAccountTierEnum];

/**
 * 
 * @export
 * @interface AccountDto
 */
export interface AccountDto {
    /**
     * 
     * @type {number}
     * @memberof AccountDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    'accountTier'?: AccountDtoAccountTierEnum;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    'tierExpiryDate'?: string;
    /**
     * 
     * @type {Array<AccountUserDto>}
     * @memberof AccountDto
     */
    'users'?: Array<AccountUserDto>;
    /**
     * 
     * @type {AccountSettings}
     * @memberof AccountDto
     */
    'accountSettings'?: AccountSettings;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDto
     */
    'isCurrent'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountDto
     */
    'unixTierExpiryDate'?: number;
}

export const AccountDtoAccountTierEnum = {
    Trial14: 'TRIAL_14',
    Monthly: 'MONTHLY',
    Annual: 'ANNUAL',
    Biennial: 'BIENNIAL',
    FeeTracker: 'FEE_TRACKER',
    FreeInvitee: 'FREE_INVITEE',
    SvBetaTrial1: 'SV_BETA_TRIAL_1',
    FeeTrackerMonthly: 'FEE_TRACKER_MONTHLY',
    FeeTrackerAnnual: 'FEE_TRACKER_ANNUAL',
    VipServicesMonthly: 'VIP_SERVICES_MONTHLY',
    VipServicesAnnual: 'VIP_SERVICES_ANNUAL',
    FreeAccount: 'FREE_ACCOUNT',
    Lifetime: 'LIFETIME',
    Trial21: 'TRIAL_21'
} as const;

export type AccountDtoAccountTierEnum = typeof AccountDtoAccountTierEnum[keyof typeof AccountDtoAccountTierEnum];

/**
 * 
 * @export
 * @interface AccountSettings
 */
export interface AccountSettings {
    /**
     * 
     * @type {number}
     * @memberof AccountSettings
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountSettings
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountSettings
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountSettings
     */
    'marketplaceId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettings
     */
    'isDemoAccount'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettings
     */
    'isXeroConnectivityEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettings
     */
    'isOrdersAndShippingEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettings
     */
    'isSv1Enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettings
     */
    'isGoogleSheetsEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettings
     */
    'isPoAndInventoryEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountSettings
     */
    'archiveClosedOrdersAndShipmentPlansInDays'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettings
     */
    'isManagementSectionEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettings
     */
    'isFeeTrackerActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountSettings
     */
    'googleSheetsUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSettings
     */
    'a2xGoogleSheetsUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSettings
     */
    'productInventoryGoogleSheetsUrl'?: string;
    /**
     * 
     * @type {Currency}
     * @memberof AccountSettings
     */
    'currency'?: Currency;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettings
     */
    'isSignupNotificationsEnabled'?: boolean;
}
/**
 * 
 * @export
 * @interface AccountSettingsDto
 */
export interface AccountSettingsDto {
    /**
     * 
     * @type {Currency}
     * @memberof AccountSettingsDto
     */
    'currency'?: Currency;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettingsDto
     */
    'isDemoAccount'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountSettingsDto
     */
    'accountName'?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountSettingsDto
     */
    'accountId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettingsDto
     */
    'isXeroConnectivityEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettingsDto
     */
    'isOrdersAndShippingEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettingsDto
     */
    'isManagementSectionEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountSettingsDto
     */
    'archiveClosedOrdersAndShipmentPlansInDays'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettingsDto
     */
    'isFeeTrackerActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettingsDto
     */
    'isSv1Enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettingsDto
     */
    'isGoogleSheetsEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountSettingsDto
     */
    'googleSheetsUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSettingsDto
     */
    'a2xGoogleSheetsUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSettingsDto
     */
    'productInventoryGoogleSheetsUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettingsDto
     */
    'isSignupNotificationsEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountSettingsDto
     */
    'isPoAndInventoryEnabled'?: boolean;
}
/**
 * 
 * @export
 * @interface AccountUser
 */
export interface AccountUser {
    /**
     * 
     * @type {number}
     * @memberof AccountUser
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountUser
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountUser
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountUser
     */
    'invitationVerificationToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountUser
     */
    'invitationExpiryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountUser
     */
    'invitationStatus'?: AccountUserInvitationStatusEnum;
    /**
     * 
     * @type {User}
     * @memberof AccountUser
     */
    'user'?: User;
    /**
     * 
     * @type {Account}
     * @memberof AccountUser
     */
    'account'?: Account;
    /**
     * 
     * @type {Array<Role>}
     * @memberof AccountUser
     */
    'roles'?: Array<Role>;
}

export const AccountUserInvitationStatusEnum = {
    None: 'NONE',
    Invited: 'INVITED',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED',
    Expired: 'EXPIRED'
} as const;

export type AccountUserInvitationStatusEnum = typeof AccountUserInvitationStatusEnum[keyof typeof AccountUserInvitationStatusEnum];

/**
 * 
 * @export
 * @interface AccountUserDto
 */
export interface AccountUserDto {
    /**
     * 
     * @type {number}
     * @memberof AccountUserDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountUserDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountUserDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountUserDto
     */
    'email'?: string;
    /**
     * 
     * @type {Array<Role>}
     * @memberof AccountUserDto
     */
    'accountRoles'?: Array<Role>;
    /**
     * 
     * @type {AccountUserSettings}
     * @memberof AccountUserDto
     */
    'accountUserSettings'?: AccountUserSettings;
    /**
     * 
     * @type {AccountUser}
     * @memberof AccountUserDto
     */
    'accountUser'?: AccountUser;
    /**
     * 
     * @type {boolean}
     * @memberof AccountUserDto
     */
    'isOwner'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountUserDto
     */
    'currentAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountUserDto
     */
    'ownedAccountId'?: number;
}
/**
 * 
 * @export
 * @interface AccountUserSettings
 */
export interface AccountUserSettings {
    /**
     * 
     * @type {number}
     * @memberof AccountUserSettings
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountUserSettings
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountUserSettings
     */
    'accountId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountUserSettings
     */
    'isFeeTrackerDailyFoundEmailEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountUserSettings
     */
    'isFeeTrackerDailyNotFoundEmailEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountUserSettings
     */
    'showFeeTrackerDailySettings'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountUserSettings
     */
    'isDailySummaryEmailEnabled'?: boolean;
}
/**
 * 
 * @export
 * @interface AccountUserUpdateDto
 */
export interface AccountUserUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof AccountUserUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountUserUpdateDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountUserUpdateDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountUserUpdateDto
     */
    'email'?: string;
    /**
     * 
     * @type {Array<Role>}
     * @memberof AccountUserUpdateDto
     */
    'accountRoles'?: Array<Role>;
}
/**
 * 
 * @export
 * @interface Currency
 */
export interface Currency {
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'currencyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'currencySymbol'?: string;
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    'sortField'?: number;
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    'precision'?: number;
}
/**
 * 
 * @export
 * @interface EmailDto
 */
export interface EmailDto {
    /**
     * 
     * @type {string}
     * @memberof EmailDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailDto
     */
    'captchaToken'?: string;
}
/**
 * 
 * @export
 * @interface NewPasswordRequest
 */
export interface NewPasswordRequest {
    /**
     * 
     * @type {number}
     * @memberof NewPasswordRequest
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewPasswordRequest
     */
    'verificationToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewPasswordRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'notificationStatus'?: NotificationDtoNotificationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'notificationType'?: NotificationDtoNotificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'notificationSource'?: NotificationDtoNotificationSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'entityType'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'messageDateTime'?: string;
}

export const NotificationDtoNotificationStatusEnum = {
    Unread: 'UNREAD',
    Read: 'READ',
    Dismissed: 'DISMISSED'
} as const;

export type NotificationDtoNotificationStatusEnum = typeof NotificationDtoNotificationStatusEnum[keyof typeof NotificationDtoNotificationStatusEnum];
export const NotificationDtoNotificationTypeEnum = {
    Info: 'INFO',
    ActionNeeded: 'ACTION_NEEDED',
    Error: 'ERROR'
} as const;

export type NotificationDtoNotificationTypeEnum = typeof NotificationDtoNotificationTypeEnum[keyof typeof NotificationDtoNotificationTypeEnum];
export const NotificationDtoNotificationSourceEnum = {
    GatewayService: 'GATEWAY_SERVICE',
    InvoiceService: 'INVOICE_SERVICE',
    NotificationService: 'NOTIFICATION_SERVICE',
    ProductService: 'PRODUCT_SERVICE',
    ReportingService: 'REPORTING_SERVICE',
    SchedulerService: 'SCHEDULER_SERVICE',
    SupplierService: 'SUPPLIER_SERVICE',
    ShopifyService: 'SHOPIFY_SERVICE',
    AmazonService: 'AMAZON_SERVICE',
    UserService: 'USER_SERVICE',
    XeroService: 'XERO_SERVICE',
    OrdersService: 'ORDERS_SERVICE',
    Sv1Service: 'SV1_SERVICE',
    PurchasingService: 'PURCHASING_SERVICE'
} as const;

export type NotificationDtoNotificationSourceEnum = typeof NotificationDtoNotificationSourceEnum[keyof typeof NotificationDtoNotificationSourceEnum];

/**
 * 
 * @export
 * @interface PaddleSubscriptionHistory
 */
export interface PaddleSubscriptionHistory {
    /**
     * 
     * @type {number}
     * @memberof PaddleSubscriptionHistory
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'paddleUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'subscriptionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'status'?: PaddleSubscriptionHistoryStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'alertName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaddleSubscriptionHistory
     */
    'marketingConsent'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'cancelUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'updateUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'subscriptionPlanId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'nextBillDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'checkoutId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'linkedSubscriptions'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaddleSubscriptionHistory
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaddleSubscriptionHistory
     */
    'unitPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'eventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'pausedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'pausedFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'cancellationEffectiveDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'pausedReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'orderId'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaddleSubscriptionHistory
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'nextRetryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'receiptUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'coupon'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'planName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'paymentMethod'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaddleSubscriptionHistory
     */
    'attemptNumber'?: number;
    /**
     * 
     * @type {Array<SubscriptionPlan>}
     * @memberof PaddleSubscriptionHistory
     */
    'subscriptionPlans'?: Array<SubscriptionPlan>;
    /**
     * 
     * @type {number}
     * @memberof PaddleSubscriptionHistory
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionHistory
     */
    'currentSVPlan'?: PaddleSubscriptionHistoryCurrentSVPlanEnum;
}

export const PaddleSubscriptionHistoryStatusEnum = {
    Active: 'ACTIVE',
    Trialing: 'TRIALING',
    PastDue: 'PAST_DUE',
    Paused: 'PAUSED',
    Deleted: 'DELETED'
} as const;

export type PaddleSubscriptionHistoryStatusEnum = typeof PaddleSubscriptionHistoryStatusEnum[keyof typeof PaddleSubscriptionHistoryStatusEnum];
export const PaddleSubscriptionHistoryCurrentSVPlanEnum = {
    Trial14: 'TRIAL_14',
    Monthly: 'MONTHLY',
    Annual: 'ANNUAL',
    Biennial: 'BIENNIAL',
    FeeTracker: 'FEE_TRACKER',
    FreeInvitee: 'FREE_INVITEE',
    SvBetaTrial1: 'SV_BETA_TRIAL_1',
    FeeTrackerMonthly: 'FEE_TRACKER_MONTHLY',
    FeeTrackerAnnual: 'FEE_TRACKER_ANNUAL',
    VipServicesMonthly: 'VIP_SERVICES_MONTHLY',
    VipServicesAnnual: 'VIP_SERVICES_ANNUAL',
    FreeAccount: 'FREE_ACCOUNT',
    Lifetime: 'LIFETIME',
    Trial21: 'TRIAL_21'
} as const;

export type PaddleSubscriptionHistoryCurrentSVPlanEnum = typeof PaddleSubscriptionHistoryCurrentSVPlanEnum[keyof typeof PaddleSubscriptionHistoryCurrentSVPlanEnum];

/**
 * 
 * @export
 * @interface PaddleSubscriptionInfo
 */
export interface PaddleSubscriptionInfo {
    /**
     * 
     * @type {number}
     * @memberof PaddleSubscriptionInfo
     */
    'vendorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionInfo
     */
    'checkoutApiUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaddleSubscriptionInfo
     */
    'productApiUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaddleSubscriptionInfo
     */
    'isSandbox'?: boolean;
    /**
     * 
     * @type {Array<SubscriptionPlan>}
     * @memberof PaddleSubscriptionInfo
     */
    'subscriptionPlans'?: Array<SubscriptionPlan>;
}
/**
 * 
 * @export
 * @interface PagePaddleSubscriptionHistory
 */
export interface PagePaddleSubscriptionHistory {
    /**
     * 
     * @type {number}
     * @memberof PagePaddleSubscriptionHistory
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePaddleSubscriptionHistory
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePaddleSubscriptionHistory
     */
    'size'?: number;
    /**
     * 
     * @type {Array<PaddleSubscriptionHistory>}
     * @memberof PagePaddleSubscriptionHistory
     */
    'content'?: Array<PaddleSubscriptionHistory>;
    /**
     * 
     * @type {number}
     * @memberof PagePaddleSubscriptionHistory
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PagePaddleSubscriptionHistory
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PagePaddleSubscriptionHistory
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePaddleSubscriptionHistory
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PagePaddleSubscriptionHistory
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePaddleSubscriptionHistory
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePaddleSubscriptionHistory
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageUser
 */
export interface PageUser {
    /**
     * 
     * @type {number}
     * @memberof PageUser
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUser
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUser
     */
    'size'?: number;
    /**
     * 
     * @type {Array<User>}
     * @memberof PageUser
     */
    'content'?: Array<User>;
    /**
     * 
     * @type {number}
     * @memberof PageUser
     */
    'number'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageUser
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageUser
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageUser
     */
    'pageable'?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageUser
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUser
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUser
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'offset'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof Pageable
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    'paged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    'unpaged'?: boolean;
}
/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'internalKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'displayName'?: string;
}
/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'unsorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface SubscriptionPlan
 */
export interface SubscriptionPlan {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlan
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlan
     */
    'userTier'?: SubscriptionPlanUserTierEnum;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlan
     */
    'accountTier'?: SubscriptionPlanAccountTierEnum;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlan
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlan
     */
    'paymentProviderPlanId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlan
     */
    'crmTagId'?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlan
     */
    'amount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionPlan
     */
    'isActiveForUser'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionPlan
     */
    'isSandbox'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlan
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlan
     */
    'termInMonths'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlan
     */
    'planSavings'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionPlan
     */
    'isAvailableInApp'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlan
     */
    'numberOfFreeTrialDays'?: number;
}

export const SubscriptionPlanUserTierEnum = {
    Trial14: 'TRIAL_14',
    Monthly: 'MONTHLY',
    Annual: 'ANNUAL',
    Biennial: 'BIENNIAL',
    FeeTracker: 'FEE_TRACKER',
    FreeInvitee: 'FREE_INVITEE',
    SvBetaTrial1: 'SV_BETA_TRIAL_1',
    FeeTrackerMonthly: 'FEE_TRACKER_MONTHLY',
    FeeTrackerAnnual: 'FEE_TRACKER_ANNUAL',
    VipServicesMonthly: 'VIP_SERVICES_MONTHLY',
    VipServicesAnnual: 'VIP_SERVICES_ANNUAL',
    FreeAccount: 'FREE_ACCOUNT',
    Lifetime: 'LIFETIME',
    Trial21: 'TRIAL_21'
} as const;

export type SubscriptionPlanUserTierEnum = typeof SubscriptionPlanUserTierEnum[keyof typeof SubscriptionPlanUserTierEnum];
export const SubscriptionPlanAccountTierEnum = {
    Trial14: 'TRIAL_14',
    Monthly: 'MONTHLY',
    Annual: 'ANNUAL',
    Biennial: 'BIENNIAL',
    FeeTracker: 'FEE_TRACKER',
    FreeInvitee: 'FREE_INVITEE',
    SvBetaTrial1: 'SV_BETA_TRIAL_1',
    FeeTrackerMonthly: 'FEE_TRACKER_MONTHLY',
    FeeTrackerAnnual: 'FEE_TRACKER_ANNUAL',
    VipServicesMonthly: 'VIP_SERVICES_MONTHLY',
    VipServicesAnnual: 'VIP_SERVICES_ANNUAL',
    FreeAccount: 'FREE_ACCOUNT',
    Lifetime: 'LIFETIME',
    Trial21: 'TRIAL_21'
} as const;

export type SubscriptionPlanAccountTierEnum = typeof SubscriptionPlanAccountTierEnum[keyof typeof SubscriptionPlanAccountTierEnum];

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'accountName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'locationTimeZone'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'bio'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isVerified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'verificationToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'tier'?: UserTierEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'tierExpiryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'verificationTokenExpiryDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'currentAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'ownedAccountId'?: number;
    /**
     * 
     * @type {Array<Account>}
     * @memberof User
     */
    'accounts'?: Array<Account>;
    /**
     * 
     * @type {Array<Role>}
     * @memberof User
     */
    'accountRoles'?: Array<Role>;
    /**
     * 
     * @type {AccountUserSettings}
     * @memberof User
     */
    'accountUserSettings'?: AccountUserSettings;
    /**
     * 
     * @type {AccountUser}
     * @memberof User
     */
    'accountUser'?: AccountUser;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isInvited'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'unixDateOfBirth'?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'unixTierExpiryDate'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'showRegistrationOnBoarding'?: boolean;
}

export const UserTierEnum = {
    Trial14: 'TRIAL_14',
    Monthly: 'MONTHLY',
    Annual: 'ANNUAL',
    Biennial: 'BIENNIAL',
    FeeTracker: 'FEE_TRACKER',
    FreeInvitee: 'FREE_INVITEE',
    SvBetaTrial1: 'SV_BETA_TRIAL_1',
    FeeTrackerMonthly: 'FEE_TRACKER_MONTHLY',
    FeeTrackerAnnual: 'FEE_TRACKER_ANNUAL',
    VipServicesMonthly: 'VIP_SERVICES_MONTHLY',
    VipServicesAnnual: 'VIP_SERVICES_ANNUAL',
    FreeAccount: 'FREE_ACCOUNT',
    Lifetime: 'LIFETIME',
    Trial21: 'TRIAL_21'
} as const;

export type UserTierEnum = typeof UserTierEnum[keyof typeof UserTierEnum];

/**
 * 
 * @export
 * @interface UserAccountSettingsDto
 */
export interface UserAccountSettingsDto {
    /**
     * 
     * @type {number}
     * @memberof UserAccountSettingsDto
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserAccountSettingsDto
     */
    'isFeeTrackerDailyFoundEmailEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAccountSettingsDto
     */
    'isFeeTrackerDailyNotFoundEmailEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAccountSettingsDto
     */
    'isDailySummaryEmailEnabled'?: boolean;
}
/**
 * 
 * @export
 * @interface UserAdminPatchDto
 */
export interface UserAdminPatchDto {
    /**
     * 
     * @type {string}
     * @memberof UserAdminPatchDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAdminPatchDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAdminPatchDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAdminPatchDto
     */
    'locationTimeZone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAdminPatchDto
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAdminPatchDto
     */
    'bio'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAdminPatchDto
     */
    'password'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserAdminPatchDto
     */
    'unixDateOfBirth'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminPatchDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAdminPatchDto
     */
    'isVerified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserAdminPatchDto
     */
    'tier'?: UserAdminPatchDtoTierEnum;
    /**
     * 
     * @type {number}
     * @memberof UserAdminPatchDto
     */
    'unixTierExpiryDate'?: number;
    /**
     * 
     * @type {AccountSettingsDto}
     * @memberof UserAdminPatchDto
     */
    'accountSettings'?: AccountSettingsDto;
}

export const UserAdminPatchDtoTierEnum = {
    Trial14: 'TRIAL_14',
    Monthly: 'MONTHLY',
    Annual: 'ANNUAL',
    Biennial: 'BIENNIAL',
    FeeTracker: 'FEE_TRACKER',
    FreeInvitee: 'FREE_INVITEE',
    SvBetaTrial1: 'SV_BETA_TRIAL_1',
    FeeTrackerMonthly: 'FEE_TRACKER_MONTHLY',
    FeeTrackerAnnual: 'FEE_TRACKER_ANNUAL',
    VipServicesMonthly: 'VIP_SERVICES_MONTHLY',
    VipServicesAnnual: 'VIP_SERVICES_ANNUAL',
    FreeAccount: 'FREE_ACCOUNT',
    Lifetime: 'LIFETIME',
    Trial21: 'TRIAL_21'
} as const;

export type UserAdminPatchDtoTierEnum = typeof UserAdminPatchDtoTierEnum[keyof typeof UserAdminPatchDtoTierEnum];

/**
 * 
 * @export
 * @interface UserAdminStatsDto
 */
export interface UserAdminStatsDto {
    /**
     * 
     * @type {number}
     * @memberof UserAdminStatsDto
     */
    'totalUsers'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAdminStatsDto
     */
    'totalActivePayingCustomers'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAdminStatsDto
     */
    'totalSignUpsLast24Hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAdminStatsDto
     */
    'betaTrial1CountdownFrom1000'?: number;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'accountName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'locationTimeZone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'bio'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'password'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'isVerified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'verificationToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'tier'?: UserDtoTierEnum;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'tierExpiryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'verificationTokenExpiryDate'?: string;
    /**
     * 
     * @type {AccountSettingsDto}
     * @memberof UserDto
     */
    'accountSettings'?: AccountSettingsDto;
    /**
     * 
     * @type {Array<Role>}
     * @memberof UserDto
     */
    'accountRoles'?: Array<Role>;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'unixDateOfBirth'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'unixTierExpiryDate'?: number;
}

export const UserDtoTierEnum = {
    Trial14: 'TRIAL_14',
    Monthly: 'MONTHLY',
    Annual: 'ANNUAL',
    Biennial: 'BIENNIAL',
    FeeTracker: 'FEE_TRACKER',
    FreeInvitee: 'FREE_INVITEE',
    SvBetaTrial1: 'SV_BETA_TRIAL_1',
    FeeTrackerMonthly: 'FEE_TRACKER_MONTHLY',
    FeeTrackerAnnual: 'FEE_TRACKER_ANNUAL',
    VipServicesMonthly: 'VIP_SERVICES_MONTHLY',
    VipServicesAnnual: 'VIP_SERVICES_ANNUAL',
    FreeAccount: 'FREE_ACCOUNT',
    Lifetime: 'LIFETIME',
    Trial21: 'TRIAL_21'
} as const;

export type UserDtoTierEnum = typeof UserDtoTierEnum[keyof typeof UserDtoTierEnum];

/**
 * 
 * @export
 * @interface UserPatchDto
 */
export interface UserPatchDto {
    /**
     * 
     * @type {number}
     * @memberof UserPatchDto
     */
    'currentAccountId'?: number;
}
/**
 * 
 * @export
 * @interface UserRegisterDto
 */
export interface UserRegisterDto {
    /**
     * 
     * @type {string}
     * @memberof UserRegisterDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterDto
     */
    'captchaToken'?: string;
}
/**
 * 
 * @export
 * @interface UserUpdateDto
 */
export interface UserUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof UserUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'accountName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'locationTimeZone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'bio'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    'password'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateDto
     */
    'unixDateOfBirth'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateDto
     */
    'showRegistrationOnBoarding'?: boolean;
}

/**
 * AccountAdminControllerApi - axios parameter creator
 * @export
 */
export const AccountAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manageExpiredInviteeUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/users/accounts/manage-expired-invitees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllUsersAccounts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/users/accounts/sync-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncUserAccountById: async (userId: number, accountId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('syncUserAccountById', 'userId', userId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('syncUserAccountById', 'accountId', accountId)
            const localVarPath = `/admin/users/{userId}/accounts/{accountId}/sync`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountAdminControllerApi - functional programming interface
 * @export
 */
export const AccountAdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountAdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manageExpiredInviteeUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manageExpiredInviteeUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountAdminControllerApi.manageExpiredInviteeUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAllUsersAccounts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAllUsersAccounts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountAdminControllerApi.syncAllUsersAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncUserAccountById(userId: number, accountId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncUserAccountById(userId, accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountAdminControllerApi.syncUserAccountById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountAdminControllerApi - factory interface
 * @export
 */
export const AccountAdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountAdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manageExpiredInviteeUsers(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.manageExpiredInviteeUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllUsersAccounts(options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.syncAllUsersAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncUserAccountById(userId: number, accountId: number, options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.syncUserAccountById(userId, accountId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountAdminControllerApi - object-oriented interface
 * @export
 * @class AccountAdminControllerApi
 * @extends {BaseAPI}
 */
export class AccountAdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountAdminControllerApi
     */
    public manageExpiredInviteeUsers(options?: RawAxiosRequestConfig) {
        return AccountAdminControllerApiFp(this.configuration).manageExpiredInviteeUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountAdminControllerApi
     */
    public syncAllUsersAccounts(options?: RawAxiosRequestConfig) {
        return AccountAdminControllerApiFp(this.configuration).syncAllUsersAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountAdminControllerApi
     */
    public syncUserAccountById(userId: number, accountId: number, options?: RawAxiosRequestConfig) {
        return AccountAdminControllerApiFp(this.configuration).syncUserAccountById(userId, accountId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AccountControllerApi - axios parameter creator
 * @export
 */
export const AccountControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {AccountUserUpdateDto} [accountUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToAccount: async (userId: number, accountUserUpdateDto?: AccountUserUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addUserToAccount', 'userId', userId)
            const localVarPath = `/users/{userId}/account/users`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountUserUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAccountUserRoles: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchAccountUserRoles', 'userId', userId)
            const localVarPath = `/users/{userId}/account/user-roles`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAccountWithUsersAndRoles: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchAccountWithUsersAndRoles', 'userId', userId)
            const localVarPath = `/users/{userId}/account`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAccountsForUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchAccountsForUser', 'userId', userId)
            const localVarPath = `/users/{userId}/accounts`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchUsersForAccount: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchUsersForAccount', 'userId', userId)
            const localVarPath = `/users/{userId}/account/users`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} deleteUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromAccount: async (userId: number, deleteUserId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeUserFromAccount', 'userId', userId)
            // verify required parameter 'deleteUserId' is not null or undefined
            assertParamExists('removeUserFromAccount', 'deleteUserId', deleteUserId)
            const localVarPath = `/users/{userId}/account/users/{deleteUserId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"deleteUserId"}}`, encodeURIComponent(String(deleteUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} updateUserId 
         * @param {AccountUserUpdateDto} [accountUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRolesForAccount: async (userId: number, updateUserId: number, accountUserUpdateDto?: AccountUserUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserRolesForAccount', 'userId', userId)
            // verify required parameter 'updateUserId' is not null or undefined
            assertParamExists('updateUserRolesForAccount', 'updateUserId', updateUserId)
            const localVarPath = `/users/{userId}/account/users/{updateUserId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"updateUserId"}}`, encodeURIComponent(String(updateUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountUserUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountControllerApi - functional programming interface
 * @export
 */
export const AccountControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {AccountUserUpdateDto} [accountUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserToAccount(userId: number, accountUserUpdateDto?: AccountUserUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserToAccount(userId, accountUserUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountControllerApi.addUserToAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAccountUserRoles(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Role>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAccountUserRoles(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountControllerApi.fetchAccountUserRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAccountWithUsersAndRoles(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAccountWithUsersAndRoles(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountControllerApi.fetchAccountWithUsersAndRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAccountsForUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAccountsForUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountControllerApi.fetchAccountsForUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchUsersForAccount(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchUsersForAccount(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountControllerApi.fetchUsersForAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} deleteUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUserFromAccount(userId: number, deleteUserId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUserFromAccount(userId, deleteUserId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountControllerApi.removeUserFromAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} updateUserId 
         * @param {AccountUserUpdateDto} [accountUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserRolesForAccount(userId: number, updateUserId: number, accountUserUpdateDto?: AccountUserUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserRolesForAccount(userId, updateUserId, accountUserUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountControllerApi.updateUserRolesForAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountControllerApi - factory interface
 * @export
 */
export const AccountControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {AccountUserUpdateDto} [accountUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToAccount(userId: number, accountUserUpdateDto?: AccountUserUpdateDto, options?: any): AxiosPromise<Array<AccountUserDto>> {
            return localVarFp.addUserToAccount(userId, accountUserUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAccountUserRoles(userId: number, options?: any): AxiosPromise<Array<Role>> {
            return localVarFp.fetchAccountUserRoles(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAccountWithUsersAndRoles(userId: number, options?: any): AxiosPromise<AccountDto> {
            return localVarFp.fetchAccountWithUsersAndRoles(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAccountsForUser(userId: number, options?: any): AxiosPromise<Array<AccountDto>> {
            return localVarFp.fetchAccountsForUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchUsersForAccount(userId: number, options?: any): AxiosPromise<Array<AccountUserDto>> {
            return localVarFp.fetchUsersForAccount(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} deleteUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromAccount(userId: number, deleteUserId: number, options?: any): AxiosPromise<AccountDto> {
            return localVarFp.removeUserFromAccount(userId, deleteUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} updateUserId 
         * @param {AccountUserUpdateDto} [accountUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRolesForAccount(userId: number, updateUserId: number, accountUserUpdateDto?: AccountUserUpdateDto, options?: any): AxiosPromise<AccountDto> {
            return localVarFp.updateUserRolesForAccount(userId, updateUserId, accountUserUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountControllerApi - object-oriented interface
 * @export
 * @class AccountControllerApi
 * @extends {BaseAPI}
 */
export class AccountControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {AccountUserUpdateDto} [accountUserUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public addUserToAccount(userId: number, accountUserUpdateDto?: AccountUserUpdateDto, options?: RawAxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).addUserToAccount(userId, accountUserUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public fetchAccountUserRoles(userId: number, options?: RawAxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).fetchAccountUserRoles(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public fetchAccountWithUsersAndRoles(userId: number, options?: RawAxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).fetchAccountWithUsersAndRoles(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public fetchAccountsForUser(userId: number, options?: RawAxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).fetchAccountsForUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public fetchUsersForAccount(userId: number, options?: RawAxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).fetchUsersForAccount(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} deleteUserId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public removeUserFromAccount(userId: number, deleteUserId: number, options?: RawAxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).removeUserFromAccount(userId, deleteUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} updateUserId 
     * @param {AccountUserUpdateDto} [accountUserUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public updateUserRolesForAccount(userId: number, updateUserId: number, accountUserUpdateDto?: AccountUserUpdateDto, options?: RawAxiosRequestConfig) {
        return AccountControllerApiFp(this.configuration).updateUserRolesForAccount(userId, updateUserId, accountUserUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AccountSettingsControllerApi - axios parameter creator
 * @export
 */
export const AccountSettingsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountSettingsByUserId: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAccountSettingsByUserId', 'userId', userId)
            const localVarPath = `/users/{userId}/account/settings`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCurrencies: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllCurrencies', 'userId', userId)
            const localVarPath = `/users/{userId}/account/currencies`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnedAccountSettingsByUserId: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getOwnedAccountSettingsByUserId', 'userId', userId)
            const localVarPath = `/users/{userId}/account/settings/owned`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {AccountSettingsDto} [accountSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountSettingsByUserId: async (userId: number, accountSettingsDto?: AccountSettingsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateAccountSettingsByUserId', 'userId', userId)
            const localVarPath = `/users/{userId}/account/settings`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountSettingsControllerApi - functional programming interface
 * @export
 */
export const AccountSettingsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountSettingsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountSettingsByUserId(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountSettingsByUserId(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountSettingsControllerApi.getAccountSettingsByUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCurrencies(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Currency>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCurrencies(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountSettingsControllerApi.getAllCurrencies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnedAccountSettingsByUserId(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwnedAccountSettingsByUserId(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountSettingsControllerApi.getOwnedAccountSettingsByUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {AccountSettingsDto} [accountSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccountSettingsByUserId(userId: number, accountSettingsDto?: AccountSettingsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccountSettingsByUserId(userId, accountSettingsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountSettingsControllerApi.updateAccountSettingsByUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountSettingsControllerApi - factory interface
 * @export
 */
export const AccountSettingsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountSettingsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountSettingsByUserId(userId: number, options?: any): AxiosPromise<AccountSettingsDto> {
            return localVarFp.getAccountSettingsByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCurrencies(userId: number, options?: any): AxiosPromise<Array<Currency>> {
            return localVarFp.getAllCurrencies(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnedAccountSettingsByUserId(userId: number, options?: any): AxiosPromise<AccountSettingsDto> {
            return localVarFp.getOwnedAccountSettingsByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {AccountSettingsDto} [accountSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountSettingsByUserId(userId: number, accountSettingsDto?: AccountSettingsDto, options?: any): AxiosPromise<AccountSettingsDto> {
            return localVarFp.updateAccountSettingsByUserId(userId, accountSettingsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountSettingsControllerApi - object-oriented interface
 * @export
 * @class AccountSettingsControllerApi
 * @extends {BaseAPI}
 */
export class AccountSettingsControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSettingsControllerApi
     */
    public getAccountSettingsByUserId(userId: number, options?: RawAxiosRequestConfig) {
        return AccountSettingsControllerApiFp(this.configuration).getAccountSettingsByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSettingsControllerApi
     */
    public getAllCurrencies(userId: number, options?: RawAxiosRequestConfig) {
        return AccountSettingsControllerApiFp(this.configuration).getAllCurrencies(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSettingsControllerApi
     */
    public getOwnedAccountSettingsByUserId(userId: number, options?: RawAxiosRequestConfig) {
        return AccountSettingsControllerApiFp(this.configuration).getOwnedAccountSettingsByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {AccountSettingsDto} [accountSettingsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountSettingsControllerApi
     */
    public updateAccountSettingsByUserId(userId: number, accountSettingsDto?: AccountSettingsDto, options?: RawAxiosRequestConfig) {
        return AccountSettingsControllerApiFp(this.configuration).updateAccountSettingsByUserId(userId, accountSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AccountUserSettingsControllerApi - axios parameter creator
 * @export
 */
export const AccountUserSettingsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountUserSettingsByUserId: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAccountUserSettingsByUserId', 'userId', userId)
            const localVarPath = `/users/{userId}/settings`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {UserAccountSettingsDto} [userAccountSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountUserSettingsByUserId: async (userId: number, userAccountSettingsDto?: UserAccountSettingsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateAccountUserSettingsByUserId', 'userId', userId)
            const localVarPath = `/users/{userId}/settings`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAccountSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountUserSettingsControllerApi - functional programming interface
 * @export
 */
export const AccountUserSettingsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountUserSettingsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountUserSettingsByUserId(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountUserSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountUserSettingsByUserId(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountUserSettingsControllerApi.getAccountUserSettingsByUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {UserAccountSettingsDto} [userAccountSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccountUserSettingsByUserId(userId: number, userAccountSettingsDto?: UserAccountSettingsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountUserSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccountUserSettingsByUserId(userId, userAccountSettingsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountUserSettingsControllerApi.updateAccountUserSettingsByUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountUserSettingsControllerApi - factory interface
 * @export
 */
export const AccountUserSettingsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountUserSettingsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountUserSettingsByUserId(userId: number, options?: any): AxiosPromise<AccountUserSettings> {
            return localVarFp.getAccountUserSettingsByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {UserAccountSettingsDto} [userAccountSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountUserSettingsByUserId(userId: number, userAccountSettingsDto?: UserAccountSettingsDto, options?: any): AxiosPromise<AccountUserSettings> {
            return localVarFp.updateAccountUserSettingsByUserId(userId, userAccountSettingsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountUserSettingsControllerApi - object-oriented interface
 * @export
 * @class AccountUserSettingsControllerApi
 * @extends {BaseAPI}
 */
export class AccountUserSettingsControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUserSettingsControllerApi
     */
    public getAccountUserSettingsByUserId(userId: number, options?: RawAxiosRequestConfig) {
        return AccountUserSettingsControllerApiFp(this.configuration).getAccountUserSettingsByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {UserAccountSettingsDto} [userAccountSettingsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountUserSettingsControllerApi
     */
    public updateAccountUserSettingsByUserId(userId: number, userAccountSettingsDto?: UserAccountSettingsDto, options?: RawAxiosRequestConfig) {
        return AccountUserSettingsControllerApiFp(this.configuration).updateAccountUserSettingsByUserId(userId, userAccountSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthControllerApi - axios parameter creator
 * @export
 */
export const AuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} accountId 
         * @param {string} verificationToken 
         * @param {AcceptAccountInviteInvitationActionEnum} invitationAction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptAccountInvite: async (userId: number, accountId: number, verificationToken: string, invitationAction: AcceptAccountInviteInvitationActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('acceptAccountInvite', 'userId', userId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('acceptAccountInvite', 'accountId', accountId)
            // verify required parameter 'verificationToken' is not null or undefined
            assertParamExists('acceptAccountInvite', 'verificationToken', verificationToken)
            // verify required parameter 'invitationAction' is not null or undefined
            assertParamExists('acceptAccountInvite', 'invitationAction', invitationAction)
            const localVarPath = `/auth/{userId}/invitation/{accountId}/{verificationToken}/{invitationAction}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"verificationToken"}}`, encodeURIComponent(String(verificationToken)))
                .replace(`{${"invitationAction"}}`, encodeURIComponent(String(invitationAction)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserRegisterDto} [userRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerTrial: async (userRegisterDto?: UserRegisterDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/register/trial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmailDto} [emailDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (emailDto?: EmailDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/forgotten-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {NewPasswordRequest} [newPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNewPassword: async (userId: number, newPasswordRequest?: NewPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('setNewPassword', 'userId', userId)
            const localVarPath = `/auth/{userId}/reset-forgotten-password`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} verificationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyUser: async (userId: number, verificationToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('verifyUser', 'userId', userId)
            // verify required parameter 'verificationToken' is not null or undefined
            assertParamExists('verifyUser', 'verificationToken', verificationToken)
            const localVarPath = `/auth/{userId}/verify/{verificationToken}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"verificationToken"}}`, encodeURIComponent(String(verificationToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} accountId 
         * @param {string} verificationToken 
         * @param {AcceptAccountInviteInvitationActionEnum} invitationAction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptAccountInvite(userId: number, accountId: number, verificationToken: string, invitationAction: AcceptAccountInviteInvitationActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptAccountInvite(userId, accountId, verificationToken, invitationAction, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthControllerApi.acceptAccountInvite']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserRegisterDto} [userRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerTrial(userRegisterDto?: UserRegisterDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUpdateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerTrial(userRegisterDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthControllerApi.registerTrial']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EmailDto} [emailDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(emailDto?: EmailDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(emailDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthControllerApi.resetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {NewPasswordRequest} [newPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setNewPassword(userId: number, newPasswordRequest?: NewPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setNewPassword(userId, newPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthControllerApi.setNewPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} verificationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyUser(userId: number, verificationToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyUser(userId, verificationToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthControllerApi.verifyUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthControllerApi - factory interface
 * @export
 */
export const AuthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} accountId 
         * @param {string} verificationToken 
         * @param {AcceptAccountInviteInvitationActionEnum} invitationAction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptAccountInvite(userId: number, accountId: number, verificationToken: string, invitationAction: AcceptAccountInviteInvitationActionEnum, options?: any): AxiosPromise<User> {
            return localVarFp.acceptAccountInvite(userId, accountId, verificationToken, invitationAction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserRegisterDto} [userRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerTrial(userRegisterDto?: UserRegisterDto, options?: any): AxiosPromise<UserUpdateDto> {
            return localVarFp.registerTrial(userRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmailDto} [emailDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(emailDto?: EmailDto, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.resetPassword(emailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {NewPasswordRequest} [newPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNewPassword(userId: number, newPasswordRequest?: NewPasswordRequest, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.setNewPassword(userId, newPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} verificationToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyUser(userId: number, verificationToken: string, options?: any): AxiosPromise<User> {
            return localVarFp.verifyUser(userId, verificationToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {number} accountId 
     * @param {string} verificationToken 
     * @param {AcceptAccountInviteInvitationActionEnum} invitationAction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public acceptAccountInvite(userId: number, accountId: number, verificationToken: string, invitationAction: AcceptAccountInviteInvitationActionEnum, options?: RawAxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).acceptAccountInvite(userId, accountId, verificationToken, invitationAction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserRegisterDto} [userRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public registerTrial(userRegisterDto?: UserRegisterDto, options?: RawAxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).registerTrial(userRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmailDto} [emailDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public resetPassword(emailDto?: EmailDto, options?: RawAxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).resetPassword(emailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {NewPasswordRequest} [newPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public setNewPassword(userId: number, newPasswordRequest?: NewPasswordRequest, options?: RawAxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).setNewPassword(userId, newPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {string} verificationToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public verifyUser(userId: number, verificationToken: string, options?: RawAxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).verifyUser(userId, verificationToken, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AcceptAccountInviteInvitationActionEnum = {
    Accept: 'ACCEPT',
    Reject: 'REJECT'
} as const;
export type AcceptAccountInviteInvitationActionEnum = typeof AcceptAccountInviteInvitationActionEnum[keyof typeof AcceptAccountInviteInvitationActionEnum];


/**
 * PaddleSubscriptionControllerApi - axios parameter creator
 * @export
 */
export const PaddleSubscriptionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: string; }} body 
         * @param {string} [body2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processSubscriptionEvent: async (body: { [key: string]: string; }, body2?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('processSubscriptionEvent', 'body', body)
            const localVarPath = `/paddle/process-subscription-event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (body !== undefined) {
                for (const [key, value] of Object.entries(body)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaddleSubscriptionControllerApi - functional programming interface
 * @export
 */
export const PaddleSubscriptionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaddleSubscriptionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: string; }} body 
         * @param {string} [body2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processSubscriptionEvent(body: { [key: string]: string; }, body2?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processSubscriptionEvent(body, body2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaddleSubscriptionControllerApi.processSubscriptionEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PaddleSubscriptionControllerApi - factory interface
 * @export
 */
export const PaddleSubscriptionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaddleSubscriptionControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: string; }} body 
         * @param {string} [body2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processSubscriptionEvent(body: { [key: string]: string; }, body2?: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.processSubscriptionEvent(body, body2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaddleSubscriptionControllerApi - object-oriented interface
 * @export
 * @class PaddleSubscriptionControllerApi
 * @extends {BaseAPI}
 */
export class PaddleSubscriptionControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: string; }} body 
     * @param {string} [body2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaddleSubscriptionControllerApi
     */
    public processSubscriptionEvent(body: { [key: string]: string; }, body2?: string, options?: RawAxiosRequestConfig) {
        return PaddleSubscriptionControllerApiFp(this.configuration).processSubscriptionEvent(body, body2, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserAdminControllerApi - axios parameter creator
 * @export
 */
export const UserAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReplaceUser: async (userId: number, userDto?: UserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('adminReplaceUser', 'userId', userId)
            const localVarPath = `/admin/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReverifyUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('adminReverifyUser', 'userId', userId)
            const localVarPath = `/admin/users/{userId}/re-verify`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {UserAdminPatchDto} [userAdminPatchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateUserDetails: async (userId: number, userAdminPatchDto?: UserAdminPatchDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('adminUpdateUserDetails', 'userId', userId)
            const localVarPath = `/admin/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAdminPatchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userDto?: UserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/admin/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return all users. Can specify sort fields and their directions by: sort=field1,asc&sort=field2,desc
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers: async (pageable: Pageable, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getAllUsers', 'pageable', pageable)
            const localVarPath = `/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAdminStats: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/users/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser: async (userDto?: UserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/users/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUsersFromKeyCloak: async (body?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/users/remove-users-from-keycloak`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/users/sync-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncUserById: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('syncUserById', 'userId', userId)
            const localVarPath = `/admin/users/{userId}/sync`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAdminControllerApi - functional programming interface
 * @export
 */
export const UserAdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReplaceUser(userId: number, userDto?: UserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReplaceUser(userId, userDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAdminControllerApi.adminReplaceUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReverifyUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReverifyUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAdminControllerApi.adminReverifyUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {UserAdminPatchDto} [userAdminPatchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateUserDetails(userId: number, userAdminPatchDto?: UserAdminPatchDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateUserDetails(userId, userAdminPatchDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAdminControllerApi.adminUpdateUserDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userDto?: UserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAdminControllerApi.createUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAdminControllerApi.deleteUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return all users. Can specify sort fields and their directions by: sort=field1,asc&sort=field2,desc
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUsers(pageable: Pageable, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsers(pageable, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAdminControllerApi.getAllUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAdminStats(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAdminStatsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAdminStats(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAdminControllerApi.getUserAdminStats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUser(userDto?: UserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUser(userDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAdminControllerApi.registerUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUsersFromKeyCloak(body?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUsersFromKeyCloak(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAdminControllerApi.removeUsersFromKeyCloak']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAllUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAllUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAdminControllerApi.syncAllUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncUserById(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncUserById(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserAdminControllerApi.syncUserById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserAdminControllerApi - factory interface
 * @export
 */
export const UserAdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReplaceUser(userId: number, userDto?: UserDto, options?: any): AxiosPromise<User> {
            return localVarFp.adminReplaceUser(userId, userDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReverifyUser(userId: number, options?: any): AxiosPromise<User> {
            return localVarFp.adminReverifyUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {UserAdminPatchDto} [userAdminPatchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateUserDetails(userId: number, userAdminPatchDto?: UserAdminPatchDto, options?: any): AxiosPromise<User> {
            return localVarFp.adminUpdateUserDetails(userId, userAdminPatchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userDto?: UserDto, options?: any): AxiosPromise<User> {
            return localVarFp.createUser(userDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: number, options?: any): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return all users. Can specify sort fields and their directions by: sort=field1,asc&sort=field2,desc
         * @param {Pageable} pageable 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers(pageable: Pageable, search?: string, options?: any): AxiosPromise<PageUser> {
            return localVarFp.getAllUsers(pageable, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAdminStats(options?: any): AxiosPromise<UserAdminStatsDto> {
            return localVarFp.getUserAdminStats(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserDto} [userDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(userDto?: UserDto, options?: any): AxiosPromise<User> {
            return localVarFp.registerUser(userDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUsersFromKeyCloak(body?: string, options?: any): AxiosPromise<string> {
            return localVarFp.removeUsersFromKeyCloak(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllUsers(options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.syncAllUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncUserById(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.syncUserById(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserAdminControllerApi - object-oriented interface
 * @export
 * @class UserAdminControllerApi
 * @extends {BaseAPI}
 */
export class UserAdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {UserDto} [userDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminControllerApi
     */
    public adminReplaceUser(userId: number, userDto?: UserDto, options?: RawAxiosRequestConfig) {
        return UserAdminControllerApiFp(this.configuration).adminReplaceUser(userId, userDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminControllerApi
     */
    public adminReverifyUser(userId: number, options?: RawAxiosRequestConfig) {
        return UserAdminControllerApiFp(this.configuration).adminReverifyUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {UserAdminPatchDto} [userAdminPatchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminControllerApi
     */
    public adminUpdateUserDetails(userId: number, userAdminPatchDto?: UserAdminPatchDto, options?: RawAxiosRequestConfig) {
        return UserAdminControllerApiFp(this.configuration).adminUpdateUserDetails(userId, userAdminPatchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserDto} [userDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminControllerApi
     */
    public createUser(userDto?: UserDto, options?: RawAxiosRequestConfig) {
        return UserAdminControllerApiFp(this.configuration).createUser(userDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminControllerApi
     */
    public deleteUser(userId: number, options?: RawAxiosRequestConfig) {
        return UserAdminControllerApiFp(this.configuration).deleteUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return all users. Can specify sort fields and their directions by: sort=field1,asc&sort=field2,desc
     * @param {Pageable} pageable 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminControllerApi
     */
    public getAllUsers(pageable: Pageable, search?: string, options?: RawAxiosRequestConfig) {
        return UserAdminControllerApiFp(this.configuration).getAllUsers(pageable, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminControllerApi
     */
    public getUserAdminStats(options?: RawAxiosRequestConfig) {
        return UserAdminControllerApiFp(this.configuration).getUserAdminStats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserDto} [userDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminControllerApi
     */
    public registerUser(userDto?: UserDto, options?: RawAxiosRequestConfig) {
        return UserAdminControllerApiFp(this.configuration).registerUser(userDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminControllerApi
     */
    public removeUsersFromKeyCloak(body?: string, options?: RawAxiosRequestConfig) {
        return UserAdminControllerApiFp(this.configuration).removeUsersFromKeyCloak(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminControllerApi
     */
    public syncAllUsers(options?: RawAxiosRequestConfig) {
        return UserAdminControllerApiFp(this.configuration).syncAllUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAdminControllerApi
     */
    public syncUserById(userId: number, options?: RawAxiosRequestConfig) {
        return UserAdminControllerApiFp(this.configuration).syncUserById(userId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} accountId 
         * @param {ActOnInvitationInvitationActionEnum} invitationAction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actOnInvitation: async (userId: number, accountId: number, invitationAction: ActOnInvitationInvitationActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('actOnInvitation', 'userId', userId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('actOnInvitation', 'accountId', accountId)
            // verify required parameter 'invitationAction' is not null or undefined
            assertParamExists('actOnInvitation', 'invitationAction', invitationAction)
            const localVarPath = `/users/{userId}/accounts/{accountId}/{invitationAction}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"invitationAction"}}`, encodeURIComponent(String(invitationAction)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserById', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTiers: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserTiers', 'userId', userId)
            const localVarPath = `/users/{userId}/user-tiers`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {UserUpdateDto} [userUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceUser: async (userId: number, userUpdateDto?: UserUpdateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('replaceUser', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification: async (userId: number, notificationDto?: NotificationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('sendNotification', 'userId', userId)
            const localVarPath = `/users/{userId}/notification`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testNewRegEmail: async (userId: number, user?: User, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('testNewRegEmail', 'userId', userId)
            const localVarPath = `/users/{userId}/test-email`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {UserPatchDto} [userPatchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExistingUser: async (userId: number, userPatchDto?: UserPatchDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateExistingUser', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPatchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('verifyEmail', 'userId', userId)
            const localVarPath = `/users/{userId}/verify-email`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} accountId 
         * @param {ActOnInvitationInvitationActionEnum} invitationAction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actOnInvitation(userId: number, accountId: number, invitationAction: ActOnInvitationInvitationActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actOnInvitation(userId, accountId, invitationAction, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.actOnInvitation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.getUserById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserTiers(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserTiers(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.getUserTiers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {UserUpdateDto} [userUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceUser(userId: number, userUpdateDto?: UserUpdateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceUser(userId, userUpdateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.replaceUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotification(userId: number, notificationDto?: NotificationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNotification(userId, notificationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.sendNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testNewRegEmail(userId: number, user?: User, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testNewRegEmail(userId, user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.testNewRegEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {UserPatchDto} [userPatchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExistingUser(userId: number, userPatchDto?: UserPatchDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExistingUser(userId, userPatchDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.updateExistingUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyEmail(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyEmail(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserControllerApi.verifyEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} accountId 
         * @param {ActOnInvitationInvitationActionEnum} invitationAction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actOnInvitation(userId: number, accountId: number, invitationAction: ActOnInvitationInvitationActionEnum, options?: any): AxiosPromise<User> {
            return localVarFp.actOnInvitation(userId, accountId, invitationAction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.getUserById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTiers(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getUserTiers(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {UserUpdateDto} [userUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceUser(userId: number, userUpdateDto?: UserUpdateDto, options?: any): AxiosPromise<User> {
            return localVarFp.replaceUser(userId, userUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification(userId: number, notificationDto?: NotificationDto, options?: any): AxiosPromise<NotificationDto> {
            return localVarFp.sendNotification(userId, notificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testNewRegEmail(userId: number, user?: User, options?: any): AxiosPromise<User> {
            return localVarFp.testNewRegEmail(userId, user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {UserPatchDto} [userPatchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExistingUser(userId: number, userPatchDto?: UserPatchDto, options?: any): AxiosPromise<User> {
            return localVarFp.updateExistingUser(userId, userPatchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail(userId: number, options?: any): AxiosPromise<User> {
            return localVarFp.verifyEmail(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {number} accountId 
     * @param {ActOnInvitationInvitationActionEnum} invitationAction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public actOnInvitation(userId: number, accountId: number, invitationAction: ActOnInvitationInvitationActionEnum, options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).actOnInvitation(userId, accountId, invitationAction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUserById(userId: string, options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getUserById(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUserTiers(userId: number, options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getUserTiers(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {UserUpdateDto} [userUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public replaceUser(userId: number, userUpdateDto?: UserUpdateDto, options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).replaceUser(userId, userUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {NotificationDto} [notificationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public sendNotification(userId: number, notificationDto?: NotificationDto, options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).sendNotification(userId, notificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public testNewRegEmail(userId: number, user?: User, options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).testNewRegEmail(userId, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {UserPatchDto} [userPatchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateExistingUser(userId: number, userPatchDto?: UserPatchDto, options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).updateExistingUser(userId, userPatchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public verifyEmail(userId: number, options?: RawAxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).verifyEmail(userId, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ActOnInvitationInvitationActionEnum = {
    Accept: 'ACCEPT',
    Reject: 'REJECT'
} as const;
export type ActOnInvitationInvitationActionEnum = typeof ActOnInvitationInvitationActionEnum[keyof typeof ActOnInvitationInvitationActionEnum];


/**
 * UserSubscriptionControllerApi - axios parameter creator
 * @export
 */
export const UserSubscriptionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaddleIntegrationInfo: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getPaddleIntegrationInfo', 'userId', userId)
            const localVarPath = `/users/{userId}/paddle-integration-info`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionHistory: async (pageable: Pageable, userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getSubscriptionHistory', 'pageable', pageable)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getSubscriptionHistory', 'userId', userId)
            const localVarPath = `/users/{userId}/subscription/history`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionInfo: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getSubscriptionInfo', 'userId', userId)
            const localVarPath = `/users/{userId}/subscription`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPlans: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getSubscriptionPlans', 'userId', userId)
            const localVarPath = `/users/{userId}/subscription-plans`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserSubscriptionControllerApi - functional programming interface
 * @export
 */
export const UserSubscriptionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserSubscriptionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaddleIntegrationInfo(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaddleSubscriptionInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaddleIntegrationInfo(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserSubscriptionControllerApi.getPaddleIntegrationInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionHistory(pageable: Pageable, userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePaddleSubscriptionHistory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionHistory(pageable, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserSubscriptionControllerApi.getSubscriptionHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionInfo(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaddleSubscriptionHistory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionInfo(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserSubscriptionControllerApi.getSubscriptionInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionPlans(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscriptionPlan>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionPlans(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserSubscriptionControllerApi.getSubscriptionPlans']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserSubscriptionControllerApi - factory interface
 * @export
 */
export const UserSubscriptionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserSubscriptionControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaddleIntegrationInfo(userId: number, options?: any): AxiosPromise<PaddleSubscriptionInfo> {
            return localVarFp.getPaddleIntegrationInfo(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionHistory(pageable: Pageable, userId: number, options?: any): AxiosPromise<PagePaddleSubscriptionHistory> {
            return localVarFp.getSubscriptionHistory(pageable, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionInfo(userId: number, options?: any): AxiosPromise<PaddleSubscriptionHistory> {
            return localVarFp.getSubscriptionInfo(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionPlans(userId: number, options?: any): AxiosPromise<Array<SubscriptionPlan>> {
            return localVarFp.getSubscriptionPlans(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserSubscriptionControllerApi - object-oriented interface
 * @export
 * @class UserSubscriptionControllerApi
 * @extends {BaseAPI}
 */
export class UserSubscriptionControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSubscriptionControllerApi
     */
    public getPaddleIntegrationInfo(userId: number, options?: RawAxiosRequestConfig) {
        return UserSubscriptionControllerApiFp(this.configuration).getPaddleIntegrationInfo(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSubscriptionControllerApi
     */
    public getSubscriptionHistory(pageable: Pageable, userId: number, options?: RawAxiosRequestConfig) {
        return UserSubscriptionControllerApiFp(this.configuration).getSubscriptionHistory(pageable, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSubscriptionControllerApi
     */
    public getSubscriptionInfo(userId: number, options?: RawAxiosRequestConfig) {
        return UserSubscriptionControllerApiFp(this.configuration).getSubscriptionInfo(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSubscriptionControllerApi
     */
    public getSubscriptionPlans(userId: number, options?: RawAxiosRequestConfig) {
        return UserSubscriptionControllerApiFp(this.configuration).getSubscriptionPlans(userId, options).then((request) => request(this.axios, this.basePath));
    }
}



